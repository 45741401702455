import { axios } from "./axios";

import { DurationType } from "../features/filter/TimeFilter/duration";

const ENDPOINT_DATES = (year: string, duration: DurationType) => {
  return `/dates?year=${year}&duration=${duration}`;
};

export type ApplicationDate = {
  name: string;
  weekNumber: number;
  dateText: string;
  year: number;
  isCurrent: boolean;
};

export async function getDates(year: string, duration: DurationType) {
  if (!year || isNaN(+year)) return [];

  const { data } = await axios.get<ApplicationDate[]>(
    ENDPOINT_DATES(year, duration)
  );

  return data;
}
