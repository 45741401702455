import React from "react";
import { StyledButtonGroup } from "../styles";
import StoreFilter from "./store";
import DepartmentFilter from "./department";
import { useMediaQuery } from "@mui/material";
import { LocationModalProvider } from "./enlarged/context";
import LocationSelector from "./enlarged";

export default function LocationFilter() {
  const isNotMobile = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  return (
    <LocationModalProvider>
      <StyledButtonGroup sx={{ width: "100%", bgcolor: "white", boxShadow: 1 }}>
        <StoreFilter />
        {isNotMobile && <DepartmentFilter />}
        <LocationSelector/>
      </StyledButtonGroup>
    </LocationModalProvider>
  );
}
