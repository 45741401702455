import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputProps,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { searchEstate } from "../../../../api/estate";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocationModal } from "./context";
import { LocationModalContextInterface } from "./model";
import CloseIcon from "@mui/icons-material/Close";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";
import DebounceInput from "../../../debounceInput";

export default function Search() {
  const [query, setQuery] = useState("");
  const ref = useRef<any>();
  const { isSearching, handleOpenSearch, handleCloseSearch, handleClose } =
    useLocationModal() as LocationModalContextInterface;
  const {
    handleLocationGroupChange,
    handleLocationChange,
    handleDepartmentChange,
  } = useHeader() as HeaderContextInterface;
  const { data } = useQuery(["estate-search", query], async () =>
    searchEstate(query)
  );
  return (
    <Box ref={ref}>
      <Grid
        container
        sx={{
          position: "relative",
        }}
      >
        <Grid item xs={12}>
          <DebounceInput
            debounceTimeout={100}
            handleDebounce={(e: string) => {
              handleOpenSearch();
              setQuery(e);
            }}
            placeholder="Search for a store name or area"
            sx={{
              m: 0
            }}
          />
        </Grid>
        {isSearching && (
          <Grid
            item
            xs={12}
            sx={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              background: "white",
              zIndex: 100,
            }}
          >
            <Card>
              <CardHeader
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={() => handleCloseSearch()}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              ></CardHeader>
              <CardContent>
                <List>
                  {data?.map((store, i) => (
                    <React.Fragment key={store.id}>
                      <ListItem
                        secondaryAction={
                          <>
                            {store.type}
                            <IconButton
                              sx={{
                                border: 1,
                                ml: 2,
                              }}
                              size="small"
                              onClick={() => {
                                if (store.type.toLowerCase() === "store") {
                                  handleLocationChange(store.id);
                                  handleDepartmentChange();
                                } else if (
                                  store.type.toLowerCase() === "region"
                                ) {
                                  handleLocationGroupChange(store.id);
                                  handleLocationChange();
                                  handleDepartmentChange();
                                }
                                handleCloseSearch();
                                handleClose();
                              }}
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </>
                        }
                      >
                        <ListItemText primary={store.name || "<No Name>"} />
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </React.Fragment>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
