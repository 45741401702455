import {
  Typography,
  Button,
  useTheme,
  Menu,
  Popover,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ManagementEffectiveness from "../../management-effectiveness";
import { AreaManagerMap } from "../area-manager-map";
import LabourMatchProgressWidget from "../labour-match-progress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledButton } from "../../../../features/filter/styles";
import CustomMenuItem from "../../../../features/filter/MenuItem";
import StoreTable from "../management-effectiveness/store-table";
import { replaceQueryString } from "../../../../core/components/utils/removeQueryString";
import { useSearchParams } from "react-router-dom";
import ResourceRiskWidget from "../resource-risk";
import SecondaryButton from "../../../../features/button/secondary";

interface Props {
  mapView?: boolean;
}
export default function MapChartVariations({ mapView }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const replaceQuery = replaceQueryString();
  const [searchParams, _] = useSearchParams();
  const open = Boolean(anchorEl);
  const ref = useRef<HTMLButtonElement>(null);
  const options = [
    "Labour match progress",
    "Management effectiveness",
    "Resource risk",
  ];
  const [selectedOption, setSelectedOption] = useState(
    searchParams.get("view") ?? options[0]
  );

  return (
    <>
      <Button
        ref={ref}
        fullWidth
        sx={{
          justifyContent: "start",
          alignItems: "center",
          color: "primary.dark",
          px: 0,
        }}
        onClick={() => {
          setAnchorEl(ref.current);
        }}
        endIcon={
          <>
            <Avatar
              sizes="small"
              sx={{
                bgcolor: "white",
                width: 28,
                height: 28,
                border: 0,
              }}
            >
              <KeyboardArrowDownIcon sx={{ color: "design.800" }} />
            </Avatar>
          </>
        }
      >
        <Typography
          variant="h5"
          color="primary.contrastText"
          sx={{
            fontWeight: 800,
          }}
        >
          {selectedOption}
        </Typography>
      </Button>
      <Popover
        id="store-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: ref.current?.clientWidth,
            padding: "10px",
          },
        }}
        sx={{
          mt: 2,
        }}
      >
        {options?.map((option) => (
          <CustomMenuItem
            onClick={() => {
              setSelectedOption(option);
              setAnchorEl(null);
            }}
            isselected={option === selectedOption}
            key={option}
          >
            {option}
          </CustomMenuItem>
        ))}
      </Popover>
      {selectedOption === options[0] && (
        <LabourMatchProgressWidget allowPagination={!!mapView} />
      )}
      {selectedOption === options[1] && <StoreTable mapView={mapView} />}
      {selectedOption === options[2] && (
        <ResourceRiskWidget mapView={mapView} />
      )}
      {!mapView && (
        <SecondaryButton
          disableRipple
          onClick={() => {
            replaceQuery("view", 0, "/map");
          }}
        >
          See all
        </SecondaryButton>
      )}
    </>
  );
}
