import { Region, SearchResult, Store } from "../models";
import { Department } from "../pages/matches/models";
import { axios } from "./axios";

export async function getStores() {
  const { data } = await axios.get<Store[]>(
    `/stores`
  );
  return data;
}

export async function getDepartments(storeId: number) {
  const { data } = await axios.get<Department[]>(
    `/stores/${storeId}/departments`
  );
  return data;
}


export async function getRegions() {
  const { data } = await axios.get<Region[]>(
    `/stores/regions`
  );
  return data;
}


export async function getStoresByRegion(regionId: number) {
  const { data } = await axios.get<Store[]>(
    `/stores/regions/${regionId}/stores`
  );
  return data;
}

export async function getRegion(regionId: number) {
  const { data } = await axios.get<Region>(
    `/stores/regions/${regionId}`
  );
  return data;
}

export async function getStore(storeId: number) {
  const { data } = await axios.get<Store>(
    `/stores/${storeId}`
  );
  return data;
}


export async function searchEstate(search: string) {
  const { data } = await axios.get<SearchResult[]>(
    `/stores/search?searchString=${search}`
  );
  return data;
}
