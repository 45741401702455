import {
  InputProps,
  TextField,
  InputAdornment,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
  sx?: SxProps<Theme> | undefined;
};

export default function DebounceInput(props: InputProps & DebounceProps) {
  const { handleDebounce, debounceTimeout, ref } = props;

  const timerRef = React.useRef<any>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return (
    <TextField
      ref={ref as any}
      sx={props.sx}
      placeholder={props.placeholder}
      fullWidth
      size="small"
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
