import React from "react";

interface Step {
  selector: string;
  content: any;
}
const dashboardSteps: Step[] = [
  {
    selector: ".first-step",
    content: (
      <>
        <h5>Welcome to the ClearMatch Demo.</h5>
        <p>
          ClearMatch enables retailers to seamlessly facilitate dynamic
          resourcing requirements, ensuring these meet the needs of customers
          and an ever-evolving retail landscape. ClearMatch eradicates
          inconsistency in labour match delivery, ensuring a consistent
          application across regions, stores, and departments – landing change
          right first time.
        </p>
        <p>
          ClearMatch scientifically identifies matches that satisfy recruitment,
          contract changes, flex and skills. Management burden is eradicated
          from labour match process by identifying the best match in each
          instance that enables timely action akin to the Difficulty of Change,
          Time to Recruit and Time to Competency. This enables Sainsbury’s to
          achieve the ‘Right people, in the right place, with the right skill’.
        </p>
        <p>
          ClearMatch enables Sainsbury’s to effectively plan for the future,
          constructing a resourcing plan that effectively meets both the
          financial and customer outcome targets. The business requires a
          solution that is fit for stores and consistently delivers against its
          Retail Priorities and business strategy – ClearMatch delivers on this.
        </p>
      </>
    ),
  },
  {
    selector: ".first-step",
    content: (
      <>
        The ClearMatch Dashboard is designed to provide{" "}
        <strong>immediate visibility of key labour metrics and progress</strong>
        . Managers can review:
        <ul>
          <li>Overall match completion progress</li>
          <li>Navigate to Quarterly Progress to review Match progress</li>
          <li>broken down by match type over this week, month and quarter</li>
        </ul>
        Managers have clarity of their current Labour Match Fit % and crucially:
        <ul>
          <li>
            <strong>
              How to close the gap with targeted intelligent actions
            </strong>
          </li>
          <li>Their progress towards their Target Labour Match Fit %</li>
        </ul>
      </>
    ),
  },
  {
    selector: ".second-step",
    content: <>Show population of graphs</>,
  },
  {
    selector: ".third-step",
    content: (
      <>
        These measures present for managers:
        <ul>
          <li>a clear view of Skills Gaps</li>
          <li>How planned training activity aligns to the ideal scenario</li>
        </ul>
      </>
    ),
  },
  {
    selector: ".quarter-1",
    content: "First quarter",
  },
  {
    selector: ".quarter-2",
    content: "Second quarter",
  },
  {
    selector: ".quarter-3",
    content: "Third quarter",
  },
  {
    selector: ".quarter-4",
    content: "Fourth quarter",
  },
  {
    selector: ".workload-input-step-1",
    content:
      "Today, our focus is on Q2 Matches. Let's begin by understanding the Workload Inputs - these are the fundamentals in labour budgeting and the drivers for Match design.",
  },
];

const workloadInputSteps: Step[] = [
  {
    selector: ".workload-input-step-1",
    content:
      "Today, our focus is on Q2 Matches. Let's begin by understanding the Workload Inputs - these are the fundamentals in labour budgeting and the drivers for Match design.",
  },
  {
    selector: ".workload-input-step-1",
    content:
      "Provides clarity on the key data and information used in the generation of Matches.",
  },
  {
    selector: ".workload-input-step-2",
    content: (
      <>
        <p>
          Effective resource deployment ensures improved alignment of skill and
          resource to meet variable task and customer demand. To support this,
          we firstly establish a Base Week. This acts as the minimum recruited
          contract level, ensuring resource isn't wasted in weeks where workload
          falls beneath contract, but also prevents capacity risks in peak
          trading times.
        </p>
        <p>
          Changes in Workload Inputs result in changes to the Base Week, along
          with Skills and Flex Requirements.
        </p>
        <p>
          In this case, we can see that:
          <ul>
            <li>
              The store Base Week has increased by 52 hours since the last
              quarter. This is because Online capacity has increased by 3% to
              meet growing demand.
            </li>
            <li>
              SmartShop is forecasted to grow by 7% in the next quarter due to a
              Nectar pricing campaign. This reduces the workload requirement for
              Staffed Checkouts. 
            </li>
            <li>
              A change in delivery schedules means more replenishment will be
              completed during trading hours, resulting in a reduction in Shift
              workload hours.
            </li>
          </ul>
        </p>
        <p>
          While the Store Base Week has increased by +52 hours, there is
          variation between store departments reflective of a change in budgeted
          task and customer demand.
        </p>
      </>
    ),
  },
  {
    selector: ".workload-input-step-3",
    content: (
      <>
        <p>
          Fundamentally, ClearMatch provides for Managers a clear business case
          for change. As forecast accuracy increases, new budgets may be
          published, also factoring inputs from multiple teams.
        </p>
        <p>
          Making this detail accessible to Managers{" "}
          <strong>
            solidifies their confidence that the recommended Matches will
            benefit their operation.
          </strong>
        </p>
        <p>
          The "This Budget vs Last Budget" shows the{" "}
          <strong>
            variation in weekly Workload Hours between the two published
            budgets.
          </strong>
        </p>
      </>
    ),
  },
  {
    selector: ".workload-input-step-4",
    content: (
      <>
        <strong>
          Key budget inputs include Unit sales, Transactions and Basket Spend
        </strong>
        - summary level detail in simple visualisations makes this accessible
        for all users.
      </>
    ),
  },
  {
    selector: ".workload-input-step-5",
    content: (
      <>
        <p>
          Retailers, like Sainsbury's Argos, demand that Managers make timely
          decisions to ensure the right outcome for colleagues and customers.
          The market for retail talent is challenging, and given the complexity
          of some in-store roles, it's{" "}
          <strong>
            crucial to plan ahead to ensure customer service and colleague
            experience can be protected
          </strong>
          .
        </p>
        <p>
          The visualisation for{" "}
          <strong> Time to Recruit & Time to Competency </strong> highlights the
          steps in the onboarding process, from identifying a vacancy or skills
          requirement, to the point in which the colleague is competent in their
          role. This is a key measure considered in Match generation.
        </p>
        <p>
          For example, for a Home Delivery Driver, it could take 14 weeks to
          recruit and achieve competency. Meanwhile, for a Trading Assistant, it
          could be 5 weeks. ClearMatch works back from the requirement to
          establish the Match process start date.
        </p>
      </>
    ),
  },
  {
    selector: ".workload-input-step-6",
    content: (
      <>
        ClearMatch harnesses this data to detect the right Matches,
        <strong>
          {" "}
          when steps should be completed to resolve forecasted gaps most
          effectively and efficiently
        </strong>
        . I.e. a short term gap may require greater Flex and or Skills, while a
        forecasted long term requirement may demand Recruitment.
      </>
    ),
  },
  {
    selector: ".match-step-1",
    content: "Matches Section",
  },
];

const matchesSteps: Step[] = [
  {
    selector: ".match-step-1",
    content: (
      <>
        The Matches screen displays a summary of the quarter's Matches, their
        Progress and constituent Match steps. Matches are either Contracts,
        Flex, Skills or Recruitment, and are{" "}
        <strong> prioritised to enable targeted and timely action</strong>.
      </>
    ),
  },
  {
    selector: ".quarter-1",
    content: "Q1: Match allocation.",
  },
  {
    selector: ".quarter-2",
    content: "Q2: Match allocation.",
  },
  {
    selector: ".quarter-3",
    content: <>Q3: The budget available in X number of days.</>,
  },
  {
    selector: ".quarter-4",
    content: <>Not available</>,
  },
  {
    selector: ".match-step-3",
    content: (
      <>
        <p>
          Quarterly Progress - The rolling percentage of Matches completed
          versus the Match completion schedule.{" "}
        </p>
        <p>
          Days Remaining - The number of days remaining until new matches become
          available.
        </p>
      </>
    ),
  },
  {
    selector: ".match-step-4",
    content: (
      <>
        Labour Match Fit % is a unified metric which measures the alignment of
        resource to the optimal plan, considering Recruitment, Contract, Flex
        and Skill. A store must have strong alignment in all four Match pillars
        in order to achieve the target Labour Match Fit % - each match pillar
        holds equal importance:
        <ul>
          <li>Recruitment – Current recruited resource versus the Base Week</li>
          <li>
            Skills – Current skills concentration versus forecasted Skills
            Requirement
          </li>
          <li>
            Flex – Current avaialbility of Flex Hours versus Flex Requirement
          </li>
          <li>
            Contract – Current contracted hours per timeslot versus Base Week
            Requirement
            <ul>
              <li>Red {"<="} 70%</li>
              <li>
                Amber {">="} 70% - {"<"} 85%
              </li>
              <li>Green {">="} 85%</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    selector: ".match-step-4",
    content: (
      <>
        <p>
          <strong>Recruitment Matches</strong>: ClearMatch is forecasting a
          recruitment need within a particular department because by Week 17,
          the store would not be able to meet customer demand with the current
          contracted hours, skills and colleague availability.
        </p>
        <p>
          <strong>Contract Matches</strong>: ClearMatch is forecasting a
          resource gap within this department based upon the variation of
          current contracted hours as well as changes in colleague availability
          and customer throuhgput.
        </p>
        <p>
          <strong>Skills Matches</strong>: ClearMatch is aware on where the
          skill gaps are likely to occur based upon the forecasted skills
          requirement, contracted hours and colleague availability.
        </p>
        <p>
          <strong>Flex Matches</strong>: ClearMatch has identified the need for
          additional Flex Hours to ensure suitable capacity.
        </p>
      </>
    ),
  },
  {
    selector: ".match-step-5",
    content: (
      <>
        Review the Business Case to justify to Herbert why there is a growing
        need for an alteration within their availability.
        <strong>
          Additional flex is required in the Customer Experience department
          (Checkouts) all day on Sunday.
        </strong>
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        <p>
          Navigate to the Changes section to{" "}
          <strong>
            understand the steps involved in completing and actioning this flex
            match by the respective dates
          </strong>{" "}
          such as reviewing or approving flex changes.
        </p>
        <p>
          <strong>Integration with existing/new solutions such as </strong>{" "}
          Kalidus (L&D), The Cube (Labour Mgt), HCM (EmpDetails), ESS (Flex) and
          Opus (Labour Time/Tasks) can further streamline management task.
        </p>
      </>
    ),
  },
  {
    selector: ".match-step-7",
    content: (
      <>
        Navigate to the Notes section to add, edit, or delete any notes you make
        whilst notifying the colleague, reviewing and approving this flex
        change. Navigate to the Files section to enter any files into this match
        via drag and drop or choosing a file from your directory which support
        progression of this flex match.
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        Navigate to the Changes section. Herbert has provided availability for
        the week and any changes have been processed.{" "}
        <strong>
          {" "}
          Store Manager has now approved this availability change, therefore the
          match is now complete.
        </strong>
      </>
    ),
  },
  {
    selector: ".match-step-8",
    content: <>Contracts Match</>,
  },
  {
    selector: ".match-step-8",
    content: (
      <>
        Review the Business Case to justify to Wilf McLeod why there is a
        growing need for an alteration within their shift pattern.{" "}
        <strong>
          {" "}
          ‘1 x Pending Leaver creates a Base Week deficit of 2 hours on
          Wednesday & Thursday morning.'
        </strong>
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        <p>
          Navigate to the Changes section to{" "}
          <strong>
            understand the steps involved in actioning and completing this
            contracts match by the respective dates such as arranging meetings
            to discuss contract changes with the colleague.
          </strong>{" "}
          such as reviewing or approving flex changes.
        </p>
        <p>
          <strong>Integration with existing/new solutions such as </strong>{" "}
          Kalidus (L&D), The Cube (Labour Mgt), HCM (EmpDetails), ESS (Flex) and
          Opus (Labour Time/Tasks) can further streamline management task.
        </p>
      </>
    ),
  },
  {
    selector: ".match-step-7",
    content: (
      <>
        Navigate to the Notes section to add, edit, or delete any notes you make
        whilst notifying the colleague, reviewing and approving this flex
        change. Navigate to the Files section to enter any files into this match
        via drag and drop or choosing a file from your directory which support
        progression of this flex match.
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        Navigate to the Changes section. Wilf{" "}
        <strong>
          has been informed about the contract change through meetings and
          accepted this.
        </strong>{" "}
      </>
    ),
  },
  {
    selector: ".match-step-4",
    content: (
      <>
        Navigate to the Progress Bars (top of page) to witness completing both
        the Flex and Contracts matches for this week{" "}
        <strong>
          {" "}
          now moves the store one step closer to their target match fit and
          ideal match completion{" "}
        </strong>
        . This enables the Online Department as well as the store run more
        productively.
      </>
    ),
  },
  {
    selector: ".match-step-9",
    content: <>Skills Match</>,
  },
  {
    selector: ".match-step-9",
    content: (
      <>
        Review the Business Case to justify to Rebecca why there is a need for
        them to be trained within Petrol.
        <strong>
          {" "}
          'Additional Petrol resource required to support upcoming maternity
          leave.'
        </strong>
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        Navigate to the Changes section to understand the steps involved in
        completing and actioning this skills match by the respective dates such
        as <strong>booking skills training or arranging meetings.</strong>
      </>
    ),
  },
  {
    selector: ".match-step-7",
    content: (
      <>
        Navigate to the Notes section to add, edit, or delete any notes during
        the{" "}
        <strong>
          {" "}
          planning and completion stages of the training for Petrol Station with
          the colleague
        </strong>
        . Navigate to the Files section to enter any files into this match via
        drag and drop or choosing a file from your directory which support
        progression of this skills match
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        Navigate to the Changes section. Rebecca{" "}
        <strong>
          {" "}
          is now competent working within Petrol to fulfil the gap within that
          skill at the store{" "}
        </strong>
        . Therefore, the Manager can now sign off as the match is now complete.
      </>
    ),
  },
  {
    selector: ".match-step-10",
    content: <>Recruitment Match</>,
  },
  {
    selector: ".match-step-10",
    content: (
      <>
        Review the Business Case to understand the reasoning behind the
        requirement for a new Home Delivery Driver.
        <strong>
          {" "}
          Planned Online Capacity has increased for the next quarter, resulting
          in a Base Week increase of 41 Hours.
        </strong>
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        Navigate to the Changes section to understand and action the steps
        involved in completing and actioning this recruitment match by the
        respective dates{" "}
        <strong> such as posting the vacancy or scheduling interviews.</strong>
      </>
    ),
  },
  {
    selector: ".match-step-7",
    content: (
      <>
        Navigate to the Notes section to add, edit, or delete any notes you make
        whilst shortlisting, interviewing and onboarding for the Home Delivery
        Driver role.
        <strong> Enhances tracebaility within the recruitment process.</strong>
      </>
    ),
  },
  {
    selector: ".match-step-6",
    content: (
      <>
        Navigate to the Changes section.{" "}
        <strong>
          A new driver has been recruited and is competent in their role
        </strong>{" "}
        therefore the Manager can sign this off and the match is now complete.
      </>
    ),
  },
  {
    selector: ".match-step-4",
    content: <>Progress Updated</>,
  },
  {
    selector: ".first-step",
    content: (
      <>
        Navigate to the Dashboard screen to witness completing this recruitment
        match
        <strong>
          {" "}
          moves the store one step closer to their target match fit and ideal
          match completion
        </strong>
        . This makes the Online Department as well as the whole store run more
        productively.
      </>
    ),
  },
];
export { dashboardSteps, workloadInputSteps, matchesSteps };
