import { axios } from "./axios";
import type {
  MatchColumns,
  MatchesStats,
  DeferOption,
  ExpandableRow,
  Department,
  Colleague,
  MatchReport,
  MatchFilter,
  MatchType,
  MatchReportStep,
  MatchCompletion,
  LabourMatchProgress,
  Match,
  DeferralReason,
  FileMetadata,
  MatchTypeStep,
  MatchStep,
  MatchNote,
  TrainingSkillManagement,
} from "../pages/matches/models";
import { dateToTicks } from "../utils/days-remaining";
import { DurationType } from "../features/filter/TimeFilter/duration";

const ENDPOINT_MATCH = (
  userId: string,
  year: string,
  locationId: string,
  weekNumber: string,
  departmentId: string
) => {
  return (
    `/matches/${userId}/${year}/${weekNumber}/${locationId}` +
    (departmentId !== undefined && departmentId.length > 0
      ? `/${departmentId}`
      : "")
  );
};
const ENDPOINT_MATCHES_STATS = (
  userId: string,
  year: string,
  locationId: string,
  weekNumber: string,
  departmentId: string
) => {
  return (
    `/matches/${userId}/report/${year}/${weekNumber}/${locationId}` +
    (departmentId !== undefined && departmentId.length > 0
      ? `/${departmentId}`
      : "")
  );
};
const ENDPOINT_MATCHES_DEFER = (matchesID: string) =>
  `matches/${matchesID}/defer/request`;

const ENDPOINT_MATCHES_DEFERRAL_APPROVAL = (matchId: string, action: string) =>
  `matches/${matchId}/deferral/${action}`;

const ENDPOINT_MATCHES_REJECT = (matchesID: string) =>
  `matches/${matchesID}/reject`;
const ENDPOINT_MATCHES_VIA_ID = (matchId: string) => `matches/${matchId}`;
export async function getUsersMatches({ queryKey }: any) {
  const [
    userId,
    weekNumber,
    locationId,
    departmentId,
    columnFilters,
    globalFilter,
    matchTypeFilter,
    pageIndex,
    pageSize,
    sorting,
    year,
  ] = queryKey;

  const { data } = await axios.get<MatchColumns[]>(
    `${ENDPOINT_MATCH(
      userId,
      year,
      locationId,
      weekNumber,
      departmentId
    )}?start=${pageIndex * pageSize}&size=${
      pageSize ?? []
    }&filters=${JSON.stringify(columnFilters ?? [])}&globalFilter=${
      globalFilter ?? []
    }&sorting=${JSON.stringify(
      sorting ?? []
    )}&matchTypeFilter=${matchTypeFilter}
    `
  );
  return data;
}

export async function getUsersMatchesStats({ queryKey }: any) {
  const [userId, weekNumber, locationId, departmentId, year] = queryKey;

  const { data } = await axios.get<MatchesStats>(
    `${ENDPOINT_MATCHES_STATS(
      userId,
      year,
      locationId,
      weekNumber,
      departmentId
    )} `
  );
  return data;
}

export async function getMatchesDefer({ queryKey }: any) {
  const [name, matchId] = queryKey;
  const { data } = await axios.get<DeferOption[]>(
    `${ENDPOINT_MATCHES_DEFER(matchId)}`
  );
  return data;
}

export async function getDepartment({ queryKey }: any) {
  const [name, matchId] = queryKey;

  const { data } = await axios.get<Department[]>(`/department-skills`);
  return data;
}

export async function getCollegues() {
  const { data } = await axios.get<Colleague[]>(`/colleagues`);
  return data;
}

export async function submitDeferToColleague(queryBody: any) {
  const { matchId, userId } = queryBody;
  return await axios.post(`matches/${matchId}/colleague/${userId}`);
}

export async function submitMatchesDefer(queryBody: any) {
  return await axios.post(`${ENDPOINT_MATCHES_DEFER(queryBody?.matchId)}`, {
    deferralReasonId: queryBody.deferralReasonId,
    comments: queryBody.comments,
  });
}

export async function getDeferReasons() {
  const { data } = await axios.get<DeferralReason[]>(
    `matches/deferral/reasons`
  );
  return data;
}

export async function submitMatchesReject(queryBody: any) {
  return await axios.post(
    `${ENDPOINT_MATCHES_REJECT(queryBody?.matchId)}`,
    queryBody
  );
}

export async function submitMatchDeferralApproval(queryBody: any) {
  return await axios.post(
    `${ENDPOINT_MATCHES_DEFERRAL_APPROVAL(
      queryBody?.matchId,
      queryBody?.action
    )}`,
    queryBody
  );
}

export async function getMatchById(matchId: number): Promise<Match> {
  const { data } = await axios.get<Match>(
    `${ENDPOINT_MATCHES_VIA_ID(matchId.toString())}`
  );
  return data;
}

export async function getMatchStepById(
  matchStepId: number
): Promise<MatchStep> {
  const { data } = await axios.get<MatchStep>(`matches/step/${matchStepId}`);
  return data;
}

export async function addNotes(queryBody: any) {
  return await axios.post(`matches/${queryBody?.matchId}/notes`, queryBody);
}
export async function getNote(matchId: number, matchStepId?: number) {
  const { data } = await axios.get<MatchNote[]>(
    `matches/${matchId}/notes?matchStepId=${matchStepId}`
  );
  return data;
}

export async function getTrainingManagementSkill(skillId: number) {
  const { data } = await axios.get<TrainingSkillManagement>(
    `matches-recruitment/training-management/${skillId}`
  );
  return data;
}

export async function addScheduledConversation(queryBody: any) {
  return await axios.post(
    `conversation/${queryBody?.matchId}/scheduled-conversation`,
    queryBody
  );
}

export async function scheduleMatchSkillTraining(queryBody: any) {
  return await axios.post(
    `matches-skill/${queryBody?.matchId}/scheduled-training`,
    queryBody
  );
}

export async function submitVacancy(queryBody: any) {
  return await axios.post(
    `matches-recruitment/${queryBody?.matchId}/vacancy-submission`,
    queryBody
  );
}

export async function offerAccepted(queryBody: any) {
  return await axios.post(
    `matches-recruitment/${queryBody?.matchId}/offer-accepted`,
    queryBody
  );
}

export async function makeOffer(queryBody: any) {
  return await axios.post(
    `matches-recruitment/${queryBody?.matchId}/offer-submission`,
    queryBody
  );
}

export async function confirmInterview(queryBody: any) {
  return await axios.post(
    `matches-recruitment/${queryBody?.matchId}/interview-confirmation`,
    queryBody
  );
}

export async function updateTrainingPlan(queryBody: any) {
  return await axios.post(
    `matches-recruitment/${queryBody?.matchId}/update-training-plan`,
    queryBody
  );
}

export async function updateTrainingPlanSkill(queryBody: any) {
  var formData = new FormData();
  for(let file of queryBody?.files){
    formData.append("files", file);
  }
  formData.append("matchStepId", queryBody?.matchStepId);
  formData.append("completed", queryBody?.completed);
  formData.append("note", queryBody?.note);
  formData.append("startDate", queryBody?.startDate);
  formData.append("endDate", queryBody?.endDate);
  return await axios.post(
    `matches-recruitment/training-management/${queryBody?.skillTrainingManagementId}`,
    formData
  );
}

export async function signOffTraining(queryBody: any) {
  return await axios.post(
    `matches-recruitment/${queryBody?.matchId}/signoff-training`,
    queryBody
  );
}

export async function completeSkillsTraining(queryBody: any) {
  return await axios.post(
    `matches-skill/${queryBody?.matchId}/complete-training`,
    queryBody
  );
}
export async function updateScheduledConversation(queryBody: any) {
  return await axios.put(
    `conversation/${queryBody?.matchId}/post-contract-scheduled-conversation`,
    queryBody
  );
}


export async function updatePostSkillConversation(queryBody: any) {
  return await axios.post(
    `skill/${queryBody?.matchId}/post-conversation`,
    queryBody
  );
}

export async function addFiles(queryBody: any) {
  var formData = new FormData();
  formData.append("file", queryBody?.file);
  formData.append("matchStepId", queryBody?.matchStepId);
  return await axios.post(`matches/${queryBody?.matchId}/files`, formData);
}

export async function getFiles(matchId: any, matchStepId?: number) {
  const { data } = await axios.get<FileMetadata[]>(
    `matches/${matchId}/files?` +
      new URLSearchParams({
        ...(!!matchStepId && { matchStepId: matchStepId?.toLocaleString() }),
      })
  );
  return data;
}

export async function downloadFile(matchId: number, fileId: number) {
  await axios.get(`matches/${matchId}/files/${fileId}`).then((response) => {
    const href = window.URL.createObjectURL(new Blob([response.data]));

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export async function updateContractChange(queryBody: any) {
  return await axios.post(
    `matches/${queryBody?.matchId}/contract/changes`,
    queryBody
  );
}

export async function getMeetingAgenda() {
  const data = await axios.get(`matches/meeting-agenda`);
  return data;
}

export async function getMatchesReport(
  duration: DurationType,
  weekNumber: number,
  year: number,
  storeId?: number,
  departmentId?: number
) {
  const { data } = await axios.get<MatchReport>(
    `/matches/reports/summary?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        timeFilter: duration.toString(),
        week: weekNumber.toString(),
        year: year.toString(),
      })
  );
  return data;
}

export async function getMatches(filter: MatchFilter) {
  let params = new URLSearchParams({
    storeId: isNaN(filter.storeId) ? "" : filter.storeId.toString(),
    duration: (filter.duration || 0).toString(),
    durationValue: filter.durationValue.toString(),
    year: filter.year.toString(),
    ...(!!filter.isCompleted && { isCompleted: true + "" }),
    ...(!!filter.isDeferred && { isDeferred: true + "" }),
    ...(!!filter.searchTerm && { searchTerm: filter.searchTerm }),
    ...(!!filter.page && { page: filter.page.toString() }),
    ...(!!filter.pageSize && { pageSize: filter.pageSize.toString() }),
  });
  if (filter.departmentIds && filter.departmentIds.length > 0) {
    filter.departmentIds.forEach((value, index) => {
      params.append(`departmentIds[${index}]`, value.toString());
    });
  }
  if (filter.matchType && filter.matchType.length > 0) {
    filter.matchType.forEach((value, index) => {
      params.append(`matchTypes[${index}]`, value.toString());
    });
  }
  if(filter.sortField){
    params.append('sortField',filter.sortField);
    params.append('ascending', filter.ascending+"")
  }
  const { data } = await axios.get<any>(`/matches?` + params);
  return data;
}

export async function getMatchesStepProgress(
  duration: DurationType,
  weekNumber: number,
  year: string,
  storeId?: number,
  departmentId?: number
) {
  const { data } = await axios.get<MatchReportStep[]>(
    `/matches/reports/step?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        timeFilter: duration.toString(),
        year,
        weekNumber: weekNumber.toString(),
      })
  );
  return data;
}

export async function getCapacityRisk(
  duration: DurationType,
  weekNumber: number,
  year: string,
  storeId?: number,
  departmentId?: number
) {
  const { data } = await axios.get<any[]>(
    `/metrics/capacity-risk?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        year,
        weekNumber: weekNumber.toString(),
      })
  );
  return data;
}

export async function getAreaCapacityReport(
  duration: DurationType,
  weekNumber: number,
  year: string,
  storeId?: number,
  departmentId?: number
) {
  const { data } = await axios.get<any[]>(
    `matches/area/reports/capacity?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        year,
        weekNumber: weekNumber.toString(),
      })
  );
  return data;
}

export async function getSkillsAlignment(
  duration: DurationType,
  weekNumber: number,
  year: string,
  storeId?: number,
  departmentId?: number
) {
  const { data } = await axios.get<any[]>(
    `/metrics/skills-alignment?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        year,
        weekNumber: weekNumber.toString(),
      })
  );
  return data;
}

export async function getLabourMatchProgress(
  duration: DurationType,
  storeId?: number,
  departmentId?: number,
  startDate?: Date
) {
  const { data } = await axios.get<LabourMatchProgress[]>(
    `/matches/reports/progress?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        timeFilter: duration.toString(),
        ...(!!startDate && { timestamp: startDate?.toLocaleString() }),
      })
  );
  return data;
}

export async function getMatchesCompletion(
  weekNumber: number,
  year: number,
  duration: DurationType,
  storeId?: number,
  departmentId?: number
) {
  const { data } = await axios.get<MatchCompletion[]>(
    `/matches/reports/completion?` +
      new URLSearchParams({
        ...(!!storeId && { storeId: storeId.toString() }),
        ...(!!departmentId && { departmentId: departmentId.toString() }),
        grouping: duration.toString(),
        weekNumber: weekNumber.toString(),
        year: year.toString(),
      })
  );
  return data;
}

export async function getMatchCounts(filter: MatchFilter) {
  let params = new URLSearchParams({
    storeId: filter.storeId.toString(),
    duration: (filter.duration || 0).toString(),
    durationValue: filter.durationValue.toString(),
    year: filter.year.toString(),    
  });
  const { data } = await axios.get<any>(`/matches/match-counts?` + params);
  return data;
}

export async function getMatchTypes() {
  const { data } = await axios.get<MatchType[]>(`/matches/types`);
  return data;
}

export async function completeMatchStep(queryBody: any) {
  var formData = new FormData();
  formData.append("file", queryBody?.file);
  return await axios.post(
    `matches/${queryBody?.matchId}/steps/${queryBody?.matchStepId}/complete`,
    formData
  );
}
