
export const ROUTES = {
  DASHBOARD: "/",
  DRIVER: "/workload-inputs",
  MATCHES: "/matches",
  MATCH: "/matches/:matchId",
  DEFERRALS: "/deferrals/:matchId/:action",
  USERSTATS: "/user/:id/stats",
  RISK: "/risk-summary",
  REPORTING: "/reporting",
  AVAILABILITY: "/availability",
  MODELPROCESSING: "/model-processing",
  MANAGEMENTEFFECTIVENESS: "/management-effectiveness",
  RISKSUMMARY: "/risk-summary",
  MAP: "/map",
  AREACAPACITY: "/area-capacity"
};
