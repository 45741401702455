import { Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocationModal } from "./context";
import { LocationModalContextInterface } from "./model";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";

export default function Recent() {
  const { history, handleClose } =
    useLocationModal() as LocationModalContextInterface;
  const {
    handleDepartmentChange,
    handleLocationChange,
    handleLocationGroupChange,
  } = useHeader() as HeaderContextInterface;
  return history.length > 0 ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          color="primary.contrastText"
          sx={{
            fontWeight: 800,
          }}
        >
          Recently Selected
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {history.map((x, i) => (
          <Chip
            label={x.text}
            key={i}
            onClick={() => {
              if (x.level === "department" && x.all) {
                handleLocationChange(x.storeId);
                handleLocationGroupChange(x.storeGroupId);
                handleDepartmentChange();
              } else if (x.level === "department" && !x.all) {
                handleDepartmentChange(x.departmentId);
                handleLocationChange(x.storeId);
                handleLocationGroupChange(x.storeGroupId);
              } else if (x.level === "store" && !x.all) {
                handleLocationChange(x.storeId);
                handleLocationGroupChange(x.storeGroupId);
                handleDepartmentChange();
              } else if (x.level === "store" && x.all) {
                handleLocationGroupChange(x.storeGroupId);
                handleLocationChange();
                handleDepartmentChange();
              }
              handleClose();
            }}
            sx={{ mr: 1, mb: 1, color: "design.800", bgcolor: "design.200" }}
          />
        ))}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
