// ** MUI Imports
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createGenericContext } from "../../utils";

export interface LayoutContextInterface{
    popupSideBar: boolean
    setPopupSidebar: React.Dispatch<React.SetStateAction<boolean>>
    backButton: React.ReactNode,
    setBackButton: React.Dispatch<React.SetStateAction<React.ReactNode>>
}
const [useLayout, LayoutContextProvider] =
  createGenericContext<LayoutContextInterface | undefined>();

const LayoutProvider = (props: any) => {
  const [popupSideBar, setPopupSidebar] = useState(false)
  const [backButton, setBackButton] = useState<React.ReactNode>()
  return (
    <LayoutContextProvider value={{
        popupSideBar,
        setPopupSidebar,
        backButton,
        setBackButton
    }}>
      {props.children}
    </LayoutContextProvider>
  );
};

export { LayoutProvider, useLayout };
