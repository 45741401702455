import React, { Fragment, ReactNode, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { thresholds } from "../../../../core/utils/color-threshold";
import { ExtendedThemeOptions } from "../../../../core/layouts/types";

interface Props {
  data: Location[];
}
interface Location {
  latitude: number;
  longitude: number;
  name: string;
  content: ReactNode;
  onClick: any;
  color: string;
}
export function AreaManagerMap({ data }: Props) {
  const [content, setContent] = useState<ReactNode>(<></>);
  const [showTooltip, setShowTooltip] = useState(false);

  let startLat = 52.34656;
  let startLong = -1.8191;

  return (
    <div
      style={{
        width: "100%",
        height: "600px",
        borderRadius: "6px",
        //borderRadius: "100%",
        background: "#fff",
        overflow: "hidden",
        margin: "1rem 0 3rem",
      }}
    >
      <MapTooltip
        title={content}
        followCursor={true}
        open={showTooltip}
        enterDelay={500}
        leaveDelay={200}
      >
        <ComposableMap
          width={300}
          overflow={"hidden"}
          height={300}
          projection="geoAzimuthalEqualArea"
          projectionConfig={{
            scale: 8150,
          }}
        >
          <ZoomableGroup center={[-1.93887, 52.11305]} zoom={2}>
            <Geographies geography={"/maps/uk.json"}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#95caa6"
                    stroke="#D6D6DA"
                  />
                ))
              }
            </Geographies>
            <Marker coordinates={[startLong, startLat]}>
              <circle r={40} fill="rgba(9, 9, 9, 0.2)" />
            </Marker>
            {data?.map((loc) => (
              <Marker
                key={loc.name}
                coordinates={[loc.longitude, loc.latitude]}
                onClick={() => loc.onClick && loc.onClick()}
                onMouseEnter={() => {
                  setShowTooltip(true);
                  setContent(loc.content);
                }}
                onMouseLeave={() => {
                  setContent(<></>);
                  setShowTooltip(false);
                }}
              >
                <circle r={2.5} fill={loc.color} />
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </MapTooltip>
    </div>
  );
}

const MapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    width: 300,
    background: "#fff",
    border: "solid 2px #ccc",
  },
});
