import { styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import DurationFilter from "./duration";
import YearFilter from "./year";
import ValueFilter from "./value";

const StyledButtonGroup = styled("div")(({ theme }: any) => ({
  borderRadius: 10,
  width: "100%",
  bgcolor: theme.palette.design["800"],
  "&:hover": {
    bgcolor: theme.palette.design["800"],
  },
  display: "flex",
}));

export default function TimeFilter() {
  const [year, setYear] = useState(new Date().getFullYear());
  const isNotMobile = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  return (
    <StyledButtonGroup sx={{ width: "100%", bgcolor: "white", boxShadow: 1 }}>
      {isNotMobile && <DurationFilter />}
      <YearFilter year={year} setYear={setYear} />
      <ValueFilter />
    </StyledButtonGroup>
  );
}
