import React from "react";
import TableDataGrid from "../../../../features/table";
import { GridColDef } from "@mui/x-data-grid";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";
import { getAreaCapacityReport } from "../../../../api/matches";
import { useQuery } from "@tanstack/react-query";
import { LabourMatchProgress } from "../../../matches/models";
import Value from "../../../../utils/value-display";
import { AreaManagerMap } from "../area-manager-map";
import SecondaryButton from "../../../../features/button/secondary";
import { replaceQueryString } from "../../../../core/components/utils/removeQueryString";
import {
  Card,
  CardHeader,
  Box,
  Typography,
  CardContent,
  Grid,
  useTheme,
} from "@mui/material";
import { thresholds } from "../../../../core/utils/color-threshold";
import { ManagementEffectiveness } from "../../../../models";
import { getPercent } from "../../../../utils";

export default function LabourMatchProgressWidget({
  allowPagination,
}: {
  allowPagination: boolean;
}) {
  const theme = useTheme()
  const { locationId, departmentId, duration, currentDate } =
    useHeader() as HeaderContextInterface;

  const { data } = useQuery(
    [
      "area-capacity",
      locationId,
      departmentId,
      duration,
      currentDate?.weekNumber,
      currentDate?.year,
    ],
    async () =>
      getAreaCapacityReport(
        duration,
        currentDate!.weekNumber,
        currentDate!.year.toString(),
        locationId,
        departmentId
      ),
    {
      enabled: true,
    }
  );

  const columns: GridColDef<LabourMatchProgress>[] = [
    {
      field: "storeName",
      headerName: "Store name",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "16px",
              color: "design.darkText",
            }}
          >
            {params.row.storeName}
          </Typography>
        );
      },
    },
    {
      field: "summary",
      headerName: "Summary",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Value
            percentage={params.row.summary}
            onTrack={params.row.summaryOnTrack}
          />
        );
      },
    },
    {
      field: "recruitment",
      headerName: "Recruitment",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Value
            percentage={params.row.recruitment}
            onTrack={params.row.recruitmentOnTrack}
          />
        );
      },
    },
    {
      field: "contracts",
      headerName: "Contracts",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Value
            percentage={params.row.contracts}
            onTrack={params.row.contractsOnTrack}
          />
        );
      },
    },
    {
      field: "skills",
      headerName: "Skills",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Value
            percentage={params.row.skills}
            onTrack={params.row.skillsOnTrack}
          />
        );
      },
    },
    {
      field: "flex",
      headerName: "Flex",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Value
            percentage={params.row.flex}
            onTrack={params.row.flexOnTrack}
          />
        );
      },
    },
  ];

  return (
    <>
      <AreaManagerMap
        data={((data || []) as LabourMatchProgress[])?.map((x) => ({
          name: x.storeName,
          latitude: x.latitude,
          longitude: x.longitude,
          onClick: () => {},
          color: thresholds(getPercent(x.summary * 100, 100),1,theme),
          content: (
            <>
              <Card>
                <CardHeader
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "design.darkText",
                          fontWeight: 800,
                        }}
                      >
                        {x.storeName}
                      </Typography>

                      <Typography
                        component="span"
                        sx={{
                          color: "design.light",
                        }}
                      >
                        {x.summary}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Recruitment
                      <Box>{x.recruitment}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Contracts
                      <Box>{x.contracts}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Flex
                      <Box>{x.flex}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Skills
                      <Box>{x.skills}</Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ),
        }))}
      />
      <TableDataGrid
        getRowId={(row: any) => row.storeId}
        data={(data || []) as LabourMatchProgress[]}
        defaultVisible={10}
        columns={columns}
        headerField="store"
        hidePaginationButton={!allowPagination}
      />
    </>
  );
}
