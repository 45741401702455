import React from "react";
import DesktopSidebar from "./view";
import { Drawer, useMediaQuery } from "@mui/material";
import { LayoutContextInterface, useLayout } from "../../context";
import Header from "../header";

export default function Sidebar() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const { popupSideBar } = useLayout() as LayoutContextInterface;
  if (isMobile) {
    return popupSideBar ? (
      <Drawer open={true}>
        <DesktopSidebar isMobile={true} />
      </Drawer>
    ) : (
      <Header />
    );
  } else {
    if (popupSideBar) {
      return (
        <Drawer open={true} sx={{ width: "216px" }}>
          <DesktopSidebar />
        </Drawer>
      );
    } else {
      return <DesktopSidebar isSmall={true} />;
    }
  }
  return <></>;
}
