// ** React Imports
import React from "react";

import { Outlet } from "react-router-dom";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Box } from "@mui/material";
import Sidebar from "./sidebar";
import { LayoutProvider } from "../context";

// ** Layout Imports
// !Do not remove this Layout import

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  leftopen?: number;
  rightopen?: number;
}

const UserLayout = () => {
  return (
    <LayoutProvider>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            backgroundColor: "design.bg",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            width: "100vw",
            mt: { xs: 20, md: 0 },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </LayoutProvider>
  );
};

export default UserLayout;
