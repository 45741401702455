import React, { useState } from "react";
import {
  HistoryType,
  Level,
  LevelType,
  LocationModalContextInterface,
} from "./model";
import { createGenericContext } from "../../../../utils";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";
import { useAuth } from "../../../../auth/context";

const [useLocationModal, LocationModalContextProvider] =
  createGenericContext<LocationModalContextInterface | undefined>();

const getViewType = (role?: string) => {
  switch (role) {
    case "Super User":
    case "Regional Manager":
      return "area";
    case "Store Manager":
    case "Department Manager":
      return "department";
  }
};
const LocationModalProvider = (props: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { user } = useAuth();
  const [levels, setLevels] = useState<Level[]>([]);
  const [viewType, setViewType] = useState<LevelType | undefined>(
    getViewType(user?.role)
  );
  const [storeId, setStoreId] = useState<number | undefined>(
    user?.storeId as number
  );
  const [storeGroupId, setStoreGroupId] = useState<number | undefined>(
    user?.storeId as number
  );
  const [history, setHistory] = useState<HistoryType[]>([]);
  const {
    handleDepartmentChange,
    handleLocationChange,
    handleLocationGroupChange,
  } = useHeader() as HeaderContextInterface;

  function onLocationSelected(name: string, id: number, type: LevelType) {
    if (
      levels.filter((s) => s.type == type && s.name == name && s.id == id)
        .length == 0
    ) {
      setLevels([
        ...levels,
        {
          name,
          id,
          type,
        },
      ]);
      if (type === "area") {
        setStoreGroupId(id);
        setViewType("store");
      } else if (type === "store") {
        setStoreId(id);
        setViewType("department");
      }
    }
  }
  function onClearLocation() {
    setLevels([]);
    setViewType("area");
  }
  function onPopLocation() {
    setLevels((levels) => {
      levels.pop();
      if (
        viewType === "store" &&
        (user?.role === "Super User" || user?.role === "Regional Manager")
      ) {
        setViewType("area");
      } else if (viewType === "department" && user?.role === "Super User") {
        setViewType("store");
      } else {
        setOpen(false);
      }
      return levels;
    });
  }
  function onSelectAllStores() {
    const area = levels[0].name;
    setHistory([
      {
        level: "store",
        text: `All stores in ${area}`,
        storeGroupId,
        all: true,
      },
      ...history.filter(
        (x) =>
          !(x.storeGroupId === storeGroupId && x.all && x.level === "store")
      ),
    ]);
    handleLocationGroupChange(storeGroupId);
    handleLocationChange();
    handleDepartmentChange();
    setOpen(false);
  }
  function onSelectAllDepartments() {
    const store = levels[1].name;
    setHistory([
      {
        level: "department",
        storeId: storeId,
        storeGroupId,
        text: `All departments in ${store}`,
        all: true,
      },
      ...history.filter(
        (x) => !(x.storeId === storeId && x.all && x.level === "department")
      ),
    ]);
    handleLocationChange(storeId);
    handleDepartmentChange();
    setOpen(false);
  }

  function onSelectDepartment(departmentId: number, departmentName: string) {
    const store = levels[levels.length - 1].name;
    setHistory([
      {
        level: "department",
        text: `${departmentName} in ${store}`,
        departmentId,
        storeId,
        storeGroupId,
      },
      ...history.filter(
        (x) =>
          !(
            x.departmentId === departmentId &&
            !x.all &&
            x.level === "department"
          )
      ),
    ]);
    handleLocationChange(storeId);
    handleDepartmentChange(departmentId);
    setOpen(false);
  }

  return (
    <LocationModalContextProvider
      value={{
        levels,
        onLocationSelected,
        onClearLocation,
        onPopLocation,
        viewType,
        open: open,
        handleClose: () => setOpen(false),
        handleOpen: () => setOpen(true),
        onSelectAllStores,
        onSelectAllDepartments,
        history,
        onSelectDepartment,
        storeId,
        storeGroupId,
        handleOpenSearch: () => setIsSearching(true),
        handleCloseSearch: () => setIsSearching(false),
        isSearching,
      }}
    >
      {props.children}
    </LocationModalContextProvider>
  );
};

export { useLocationModal, LocationModalProvider };
