import { Button, styled } from "@mui/material";

const SecondaryButton = styled(Button)({
    border: "solid 1px #003DA5",
    color: "#003DA5",
    marginTop: "20px",
    textTransform: "none",
    background: "none",
    fontSize: "16px",
    fontWeight: 400,
    width: "200px",
    ":hover": {
      background: "none",
    },
  });
export default SecondaryButton;