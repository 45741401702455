import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocationModal } from "./context";
import { LocationModalContextInterface } from "./model";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";
import Search from "./search";
import Recent from "./recent";
export default function Area() {
  const { onLocationSelected, isSearching } =
    useLocationModal() as LocationModalContextInterface;
  const { regions } = useHeader() as HeaderContextInterface;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color="primary.contrastText"
          sx={{
            fontWeight: 800,
          }}
        >
          Location Selector
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Search />
      </Grid>
      {!isSearching && (
        <>
          <Grid item xs={12}>
            <Recent />
          </Grid>
          <Grid container item xs={12} spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary.contrastText"
                sx={{
                  fontWeight: 800,
                }}
              >
                Select area
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ width: "100%" }}>
                {regions?.map((region, i) => (
                  <React.Fragment key={region.regionId}>
                    <ListItem
                      secondaryAction={
                        <IconButton
                          sx={{
                            border: 1,
                          }}
                          size="small"
                          onClick={() => {
                            onLocationSelected(
                              region.name,
                              region.regionId,
                              "area"
                            );
                          }}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={region.name} />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
