import React, { useEffect, useRef, useState } from "react";
import { StyledButton } from "../styles";
import { Button, Popover } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useHeader } from "../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../layouts/components/header/models";
import CustomMenuItem from "../MenuItem";
import { useLocationModal } from "./enlarged/context";
import { LocationModalContextInterface } from "./enlarged/model";
import { useAuth } from "../../../auth/context";

export default function DepartmentFilter() {
  const [anchorDepartmentEl, setAnchorDepartmentEl] =
    useState<HTMLElement | null>();
  const DepartmentButtonRef = useRef<HTMLDivElement>(null);
  const openDepartmentMenu = Boolean(anchorDepartmentEl);
  const handleDepartmentMenuClose = () => {
    setAnchorDepartmentEl(undefined);
  };
  const handleDepartmentMenuClick = () => {
    if (departments?.length && departments.length > 5) {
      handleOpen();
    } else {
      if (DepartmentButtonRef) {
        setAnchorDepartmentEl(DepartmentButtonRef.current);
      }
    }
  };
  const { handleDepartmentChange, departments, departmentId } =
    useHeader() as HeaderContextInterface;

  const { user } = useAuth();

  const { handleOpen, onSelectDepartment, onLocationSelected, levels } =
    useLocationModal() as LocationModalContextInterface;

  useEffect(() => {
    if (departments && departments.length > 0 && levels && levels.length > 0) {
      if (user?.role === "Department Manager") {
        onLocationSelected(
          departments[0].name,
          departments[0].departmentId,
          "department"
        );
        onSelectDepartment(departments[0].departmentId, departments[0].name);
        handleDepartmentChange(departments[0].departmentId);
      }
    }
  }, [user, departments, levels]);

  return (
    <>
      <StyledButton
        aria-controls={openDepartmentMenu ? "Department-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openDepartmentMenu ? "true" : undefined}
        aria-describedby="Department-menu"
        ref={DepartmentButtonRef}
      >
        <Button
          fullWidth
          sx={{
            justifyContent: "start",
            alignItems: "center",
            color: "design.darkText",
          }}
          onClick={handleDepartmentMenuClick}
        >
          {departments?.find((x) => x.departmentId === departmentId)?.name ||
            "All Departments"}
          <ExpandMoreIcon sx={{ ml: "auto", color: "design.darkText" }} />
        </Button>
      </StyledButton>
      <Popover
        id="Department-menu"
        anchorEl={anchorDepartmentEl}
        open={openDepartmentMenu}
        onClose={handleDepartmentMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: DepartmentButtonRef.current?.clientWidth,
            padding: "10px",
          },
        }}
        sx={{
          mt: 2,
        }}
      >
        {departments?.map((department) => (
          <CustomMenuItem
            key={department.departmentId}
            onClick={() => {
              onSelectDepartment(department.departmentId, department.name);
              handleDepartmentChange(department.departmentId);
              handleDepartmentMenuClose();
            }}
            isselected={department.departmentId === departmentId}
          >
            {department.name}
          </CustomMenuItem>
        ))}
      </Popover>
    </>
  );
}
