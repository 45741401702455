import React, { useEffect, useState } from "react";
import { createGenericContext } from "../utils";
import { useGetProfile } from "./use-get-profile";
import { IProfileContext } from "./model";

const [useAuth, AuthContextProvider] = createGenericContext<IProfileContext>();

const AuthProvider = ({ children }: any) => {
  const { data, isLoading, refetch, error } = useGetProfile();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (data && !isLoading) {
      setIsAuthenticated(true);
    }
  }, [data, isLoading]);

  useEffect(() => {
    let axiosError: any = error;

    if (
      axiosError &&
      axiosError.response &&
      axiosError.response.status === 401
    ) {
      window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/login`;
    }
  }, [error]);

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/logout`;
  };

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>
      <AuthContextProvider
        value={{ user: data, isLoading, refetch, logout, isAuthenticated }}
      >
        {children}
      </AuthContextProvider>
    </>
  );
};

export { useAuth };
export default AuthProvider;
