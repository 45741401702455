import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { ROUTES } from "./routes-list";

import UserLayout from "../../layouts/components/UserLayout";
import { ProtectedRoute } from "./protected-route";
import { HeaderProvider } from "../../layouts/components/header/context/context";
import { TourGuideProvider } from "../steps/context";
import AuthProvider from "../../auth/context";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MapView from "../../pages/map";
import AreaCapacity from "../../pages/area capacity";
const Dashboard = lazy(() => import("../../pages/dashboard"));
const Driver = lazy(() => import("../../pages/drivers"));
const Matches = lazy(() => import("../../pages/matches"));
const Match = lazy(() => import("../../pages/matches/detail-match"));
const Deferrals = lazy(() => import("../../pages/deferrals"));
const ManagementEffectiveness = lazy(
  () => import("../../pages/dashboard/management-effectiveness")
);
const RiskSummary = lazy(()=>import("../../pages/risk-summary"))

const useRenderRoutes = () =>
  useRoutes([
    {
      path: ROUTES.DASHBOARD,
      element: (
        <AuthProvider>
          <ProtectedRoute>
            <HeaderProvider>
              <TourGuideProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <UserLayout />
                </LocalizationProvider>
              </TourGuideProvider>
            </HeaderProvider>
          </ProtectedRoute>
        </AuthProvider>
      ),
      children: [
        { index: true, element: <Dashboard /> },
        { path: ROUTES.DRIVER, element: <Driver /> },
        { path: ROUTES.MATCHES, element: <Matches /> },
        { path: ROUTES.MATCH, element: <Match /> },
        { path: ROUTES.DEFERRALS, element: <Deferrals /> },
        { path: ROUTES.USERSTATS, element: <Dashboard /> },
        {
          path: ROUTES.MANAGEMENTEFFECTIVENESS,
          element: <ManagementEffectiveness />,
        },
        {
          path: ROUTES.RISKSUMMARY,
          element: <RiskSummary />,
        },
        {
          path: ROUTES.MAP,
          element: <MapView />,
        },
        {
          path: ROUTES.AREACAPACITY,
          element: <AreaCapacity/>
        }
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

export default { useRenderRoutes };
