import {
  Container,
  Grid,
  Button,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import LocationFilter from "../../features/filter/LocationFilter";
import TimeFilter from "../../features/filter/TimeFilter";
import getPageLink from "../../core/components/utils/getPageLink";
import { useLayout, LayoutContextInterface } from "../../layouts/context";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AreaCapacityComponent from "../dashboard/components/area-capacity";

export default function AreaCapacity() {
  const { setBackButton } = useLayout() as LayoutContextInterface;

  const backLink = getPageLink("/");
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  useEffect(() => {
    setBackButton(
      <NavLink
        to={`${backLink}`}
        style={{
          textDecoration: "none",
          display: "block",
        }}
      >
        <Button
          sx={{ color: "design.800" }}
          startIcon={
            <Avatar
              sizes="small"
              sx={{
                bgcolor: "white",
                width: 28,
                height: 28,
                borderColor: "design.bg",
                border: 1,
              }}
            >
              <KeyboardArrowLeftIcon sx={{ color: "design.800" }} />
            </Avatar>
          }
        >
          Back
        </Button>
      </NavLink>
    );
    return () => {
      setBackButton(undefined);
    };
  }, []);
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2} p={5}>
        {isMobile && (
          <Grid item xs={12}>
            <NavLink
              to={`${backLink}`}
              style={{
                textDecoration: "none",
                marginBottom: "10px",
                display: "block",
              }}
            >
              <Button
                sx={{ color: "design.800" }}
                startIcon={
                  <Avatar
                    sizes="small"
                    sx={{ bgcolor: "white", width: 28, height: 28 }}
                  >
                    <KeyboardArrowLeftIcon sx={{ color: "design.800" }} />
                  </Avatar>
                }
              >
                Back
              </Button>
            </NavLink>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            variant="h3"
            color="primary.contrastText"
            sx={{
              fontWeight: 800,
            }}
          >
            Area capacity
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LocationFilter />
        </Grid>
        <Grid item xs={12}>
          <TimeFilter />
        </Grid>
        <Grid item xs={12} mt={9}>
          <AreaCapacityComponent main />
        </Grid>
      </Grid>
    </Container>
  );
}
