import React, { ReactNode } from "react";
import { StyledMenuItem } from "./styles";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  onClick: Function;
  isselected: boolean;
  children: ReactNode;
}
export default function CustomMenuItem({
  onClick,
  isselected,
  children,
}: Props) {
  return (
    <StyledMenuItem onClick={() => onClick()} isselected={isselected}>
      {children}
      <CheckIcon
        sx={{
          visibility: isselected ? "visible" : "hidden",
          color: "design.800",
        }}
      />
    </StyledMenuItem>
  );
}
