import { Grid, Typography } from "@mui/material";
import React from "react";
import Area from "./area";
import Department from "./department";
import { useLocationModal } from "./context";
import { LocationModalContextInterface } from "./model";
import Recent from "./recent";
import Search from "./search";
import Store from "./store";
import CustomFilter from "../../CustomFilter";

export default function LocationSelector() {
  const { viewType, onPopLocation, open, handleClose } =
    useLocationModal() as LocationModalContextInterface;
  return (
    <>
      <CustomFilter
        open={open}
        handleClose={handleClose}
        handleBack={onPopLocation}
      >
        {viewType === "area" && <Area />}
        {viewType === "store" && <Store />}
        {viewType === "department" && <Department />}
      </CustomFilter>
    </>
  );
}
