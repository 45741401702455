import React, { useState } from "react";
import { StyledButton } from "../styles";
import { DurationType } from "./duration";
import { Box, Button, IconButton, Popover, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomMenuItem from "../MenuItem";
import { useHeader } from "../../../layouts/components/header/context/context";

interface Props {
  year: number;
  duration: DurationType;
}

export default function ValueFilter() {
  const { currentDate, handleDateChange, dates: values } = useHeader()!;
  const isNotMobile = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [anchorValueEl, setAnchorValueEl] = useState<HTMLElement | null>();
  const valueButtonRef = React.useRef<HTMLDivElement>(null);
  const openValueMenu = Boolean(anchorValueEl);
  const handleValueMenuClose = () => {
    setAnchorValueEl(undefined);
  };
  const handleValueMenuClick = () => {
    if (valueButtonRef) {
      setAnchorValueEl(valueButtonRef.current);
    }
  };

  const prevWeek = () => {
    if (!values) return;

    let index = values.indexOf(currentDate!);

    if (index - 1 != -1) {
      handleDateChange(values[index - 1]);
    }
  };

  const nextWeek = () => {
    if (!values) return;

    let index = values.indexOf(currentDate!);

    if (values.length != index + 1) {
      handleDateChange(values[index + 1]);
    }
  };

  if (!values) return <></>;

  return (
    <>
      <StyledButton
        aria-controls={openValueMenu ? "value-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openValueMenu ? "true" : undefined}
        aria-describedby="value-menu"
        ref={valueButtonRef}
      >
        <Button
          fullWidth
          sx={{
            justifyContent: "start",
            alignItems: "center",
            color: "design.darkText",
          }}
          onClick={handleValueMenuClick}
        >
          <Box component="span" sx={{ ml: "auto" }}>
            {currentDate?.name}
          </Box>
        </Button>
        {isNotMobile && (
          <Box sx={{ ml: "auto", display: "inline-flex" }}>
            <IconButton
              sx={{ border: 1, mr: 3, p: 0.2, borderColor: "primary.lighter" }}
              onClick={() => {
                prevWeek();
              }}
            >
              <ChevronLeftIcon sx={{ color: "design.darkText" }} />
            </IconButton>
            <IconButton
              sx={{ border: 1, p: 0.2, borderColor: "primary.lighter" }}
              onClick={() => {
                nextWeek();
              }}
            >
              <ChevronRightIcon sx={{ color: "design.darkText" }} />
            </IconButton>
          </Box>
        )}
      </StyledButton>
      <Popover
        anchorEl={anchorValueEl}
        open={openValueMenu}
        onClose={handleValueMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: isNotMobile ? valueButtonRef.current?.clientWidth : "100%",
            padding: "10px",
          },
        }}
        sx={{
          mt: 2,
        }}
      >
        {values.map((x, i) => {
          return (
            <CustomMenuItem
              key={i}
              onClick={() => {
                handleDateChange(x);
                handleValueMenuClose();
              }}
              isselected={currentDate == x}
            >
              {x.name}
              <Box component="span" sx={{ mx: "auto", color: "design.light" }}>
                {x.dateText}
              </Box>
            </CustomMenuItem>
          );
        })}
      </Popover>
    </>
  );
}
