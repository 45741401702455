import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { ROUTES } from "../../../navigation/routes/routes-list";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import React from "react";

const navigations = [
  {
    subheader: "Insights",
    items: [
      {
        title: "Dashboard",
        icon: <DashboardOutlinedIcon />,
        path: ROUTES.DASHBOARD,
      },
      {
        title: "Matches",
        icon: <PersonOutlineRoundedIcon />,
        path: ROUTES.MATCHES,
      },
      {
        title: "Risk",
        icon: <WarningAmberRoundedIcon />,
        path: ROUTES.RISK,
      },
      {
        title: "Reporting",
        icon: <InsertChartOutlinedIcon />,
        path: ROUTES.REPORTING,
      }
    ],
  },
  {
    subheader: "Data management",
    items: [
      {
        title: "Workload inputs",
        icon: <EditNoteRoundedIcon />,
        path: ROUTES.DRIVER,
      },
      {
        title: "Availability",
        icon: <EventAvailableOutlinedIcon />,
        path: ROUTES.AVAILABILITY,
      },
      {
        title: "Model Processing",
        icon: <BarChartOutlinedIcon />,
        path: ROUTES.MODELPROCESSING,
      }
    ],
  },
];

export default navigations