import { Box, Typography } from "@mui/material";
import React from "react";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
export default function BehindIcon({ text }: any) {
  return (
    <Box
      sx={{
        color: "#FE1950",
        bgcolor: "#FEECF2",
        p: text ? 1 : 0,
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        ml: 1,
      }}
    >
      <Box
        sx={{
          color: "#FDFEFF",
          bgcolor: "#FE1950",
          p: 1,
          borderRadius: 1,
        }}
      >
        <PriorityHigh sx={{ verticalAlign: "middle" }} />
      </Box>
      {text && <Typography sx={{ ml: 1, color: "#FE1950" }}>{text}</Typography>}
    </Box>
  );
}
