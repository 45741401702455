import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const replaceQueryString = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  return useCallback(
    (name: string, value: any, route: string) => {
      if (!!value) {
        searchParams.set(name, value);
      } else {
        searchParams.delete(name);
      }
      setSearchParams(searchParams);
      navigate(route + "?" + searchParams.toString());
    },
    [searchParams]
  );
};
