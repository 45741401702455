import React, { useMemo, useState } from "react";
import { StyledButton } from "../styles";
import { Box, Button, IconButton, Popover, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomMenuItem from "../MenuItem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useAuth } from "../../../auth/context";
import moment from "moment";
import { useHeader } from "../../../layouts/components/header/context/context";

const formatYear = (year: number) => {
  const endYear = year + 1;
  const formattedYear = `${String(year).slice(-2)}/${String(endYear).slice(
    -2
  )}`;
  return formattedYear;
};

interface Props {
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

export default function YearFilter({ year, setYear }: Props) {
  const [anchorYearEl, setAnchorYearEl] = useState<HTMLElement | null>();
  const yearButtonRef = React.useRef<HTMLDivElement>(null);
  const openYearMenu = Boolean(anchorYearEl);
  const isNotMobile = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const { user } = useAuth();
  const { currentDate } = useHeader()!;

  const years = useMemo(() => {
    const years = [];

    if (!user) return [];

    for (let i = 0; i < user?.companyPeriodStartDates.length; i++) {
      years.push(moment(user?.companyPeriodStartDates[i]).year());
    }
    return years;
  }, [user]);

  const handleYearMenuClose = () => {
    setAnchorYearEl(undefined);
  };
  const handleYearMenuClick = () => {
    if (yearButtonRef) {
      setAnchorYearEl(yearButtonRef.current);
    }
  };
  return (
    <>
      <StyledButton
        aria-controls={openYearMenu ? "year-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openYearMenu ? "true" : undefined}
        aria-describedby="year-menu"
        ref={yearButtonRef}
      >
        <Button
          fullWidth
          sx={{
            justifyContent: "start",
            alignItems: "center",
            color: "design.darkText",
          }}
          onClick={handleYearMenuClick}
        >
          {!isNotMobile && <AccessTimeIcon sx={{ mr: 2 }} />}
          FY {formatYear(year)}
        </Button>
        {isNotMobile && (
          <Box sx={{ ml: "auto", display: "inline-flex" }}>
            <IconButton
              sx={{ border: 1, mr: 3, p: 0.2, borderColor: "primary.lighter" }}
              onClick={() => {
                setYear((year) => year - 1);
              }}
            >
              <ChevronLeftIcon sx={{ color: "design.darkText" }} />
            </IconButton>
            <IconButton
              sx={{ border: 1, p: 0.2, borderColor: "primary.lighter" }}
              onClick={() => {
                setYear((year) => year + 1);
              }}
            >
              <ChevronRightIcon sx={{ color: "design.darkText" }} />
            </IconButton>
          </Box>
        )}
      </StyledButton>
      <Popover
        anchorEl={anchorYearEl}
        open={openYearMenu}
        onClose={handleYearMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: isNotMobile ? yearButtonRef.current?.clientWidth : "100%",
            padding: "10px",
          },
        }}
        sx={{
          mt: 2,
        }}
      >
        {years.map((x) => (
          <CustomMenuItem
            key={x}
            onClick={() => {
              setYear(x);
              handleYearMenuClose();
            }}
            isselected={currentDate?.year === x}
          >
            FY {formatYear(x)}
          </CustomMenuItem>
        ))}
      </Popover>
    </>
  );
}
