import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  List,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocationModal } from "./context";
import { LocationModalContextInterface } from "./model";
import { useQuery } from "@tanstack/react-query";
import { getDepartments, getStore } from "../../../../api/estate";
import Recent from "./recent";
import Search from "./search";
export default function Department() {
  const [selected, setSelected] = useState<number | "all" | undefined>("all");
  const {
    onClearLocation,
    onSelectAllDepartments,
    onSelectDepartment,
    storeId,
  } = useLocationModal() as LocationModalContextInterface;

  const { data: departments } = useQuery(
    ["departments", storeId],
    async () => {
      if (!!storeId) {
        return await getDepartments(storeId);
      }
    },
    {
      enabled: !!storeId,
    }
  );

  const { data: store } = useQuery(
    ["store", storeId],
    async () => {
      if (!!storeId) {
        return await getStore(storeId);
      }
    },
    {
      enabled: !!storeId,
    }
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color="primary.contrastText"
          sx={{
            fontWeight: 800,
          }}
        >
          {store?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Recent />
      </Grid>

      <Grid container item xs={12} spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <Radio
              checked={selected === "all"}
              onChange={(_, checked) => {
                if (checked) {
                  setSelected("all");
                }
              }}
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            All departments
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color="primary.contrastText"
            sx={{
              fontWeight: 800,
            }}
          >
            Select Department
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List sx={{ width: "100%" }}>
            <RadioGroup
              aria-labelledby="department-selector"
              value={selected}
              onChange={(e, v) => {
                setSelected(parseInt(v));
              }}
              name="department-selection"
            >
              {departments?.map((department, i) => (
                <>
                  <FormControlLabel
                    value={department.departmentId}
                    control={<Radio />}
                    label={department.name}
                  />
                  <Divider variant="fullWidth" component="li" />
                </>
              ))}
            </RadioGroup>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ color: "white", bgcolor: "design.800" }}
            disabled={!selected}
            onClick={() => {
              if (selected === "all") {
                onSelectAllDepartments();
              } else if (selected && departments) {
                const dpt = departments?.filter(
                  (s) => s.departmentId == selected
                )[0];
                onSelectDepartment(selected, `Department ${dpt!.name}`);
              }
            }}
          >
            Confirm selection
          </Button>
          <Button onClick={() => onClearLocation()}>
            Clear selection and search again
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
