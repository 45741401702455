import "./style.css";
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api/query-client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ThemeComponent from "./core/theme/ThemeComponent";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./core/context/settingsContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  <SettingsProvider>
    <SettingsConsumer>
      {({ settings }) => {
        return (
          <ThemeComponent settings={settings}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ThemeComponent>
        );
      }}
    </SettingsConsumer>
  </SettingsProvider>
);
