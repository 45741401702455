import { MenuItem, styled } from "@mui/material";

const StyledButtonGroup = styled("div")(({ theme }: any) => ({
  borderRadius: 10,
  width: "100%",
  bgcolor: theme.palette.design["800"],
  "&:hover": {
    bgcolor: theme.palette.design["800"],
  },
  display: "flex",
}));

const StyledButton = styled("div")(({ theme }: any) => ({
  flexGrow: 1,
  flexBasis: 0,
  minWidth: 0,
  color: theme.palette.design["800"],
  borderColor: `${theme.palette.design["200"]} !important `,
  borderRight: "1px solid",
  "&:hover": {
    backgroundColor: "white",
  },
  ".MuiButton-endIcon": {
    marginLeft: "auto",
  },
  display: "flex",
  alignItems: "center",
  padding: "7px 15px",
}));

const StyledMenuItem = styled(MenuItem)<{
  isselected: boolean;
}>(({ theme, isselected }) => ({
  color: isselected
    ? (theme as any).palette.design["800"]
    : (theme as any).palette.design["1000"],
  justifyContent: "space-between",
  backgroundColor: isselected && (theme.palette as any).design["200"],
  borderRadius: "12px",
  padding: "12px",
}));

export { StyledButtonGroup, StyledButton, StyledMenuItem };
