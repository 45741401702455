import { DurationType } from "../features/filter/TimeFilter/duration";
import {
  ManagementEffectiveness,
  MetricOverview,
  ResourceRisk,
} from "../models";
import { dateToTicks } from "../utils/days-remaining";
import { axios } from "./axios";

export async function getMetricOverview(
  weekNumber: number,
  year: number,
  duration: DurationType,
  regionId?: number,
  storeId?: number,
  departmentId?: number
) {
  let params = new URLSearchParams({
    ...(!!storeId && { storeId: storeId.toString() }),
    ...(!!departmentId && { departmentId: departmentId.toString() }),
    ...(!!regionId && { regionId: regionId.toString() }),
    week: weekNumber.toString(),
    year: year.toString(),
    duration: (duration || 0).toString(),
  });
  const { data } = await axios.get<MetricOverview>(
    `/metrics/overview?${params}`
  );
  return data;
}

export async function getManagementEffectiveness(
  weekNumber: number,
  yearNumber: number,
  duration: DurationType,
  regionId?: number,
  storeId?: number,
  pageNo?: number,
  byDepartment?: boolean
) {
  let params = new URLSearchParams({
    ...(!!storeId && { storeId: storeId.toString() }),
    ...(!!regionId && { regionId: regionId.toString() }),
    ...(!!pageNo && { pageNo: pageNo.toString() }),
    year: yearNumber.toString(),
    week: weekNumber.toString(),
    duration: (duration || 0).toString(),
    byDepartment: (byDepartment || false).toString(),
  });
  const { data } = await axios.get<ManagementEffectiveness[]>(
    `/metrics/management-effectiveness?${params}`
  );
  return data;
}

export async function getMetric(
  year: number,
  week: number,
  duration: DurationType,
  regionId?: number,
  storeId?: number,
  byDepartment?: boolean
) {
  let params = new URLSearchParams({
    ...(!!storeId && { storeId: storeId.toString() }),
    ...(!!regionId && { regionId: regionId.toString() }),
    year: year.toString(),
    week: week.toString(),
    duration: (duration || 0).toString(),
    byDepartment: (byDepartment || false).toString(),
  });
  const { data } = await axios.get<ManagementEffectiveness>(
    `/metrics?${params}`
  );
  return data;
}

export async function getResourceRisk(
  weekNumber: number,
  year: string,
  duration: DurationType,
  regionId?: number,
  storeId?: number,
  pageNo?: number
) {
  let params = new URLSearchParams({
    ...(!!storeId && { storeId: storeId.toString() }),
    ...(!!regionId && { regionId: regionId.toString() }),
    ...(!!pageNo && { pageNo: pageNo.toString() }),
    year,
    weekNumber: weekNumber.toString(),
    immediacy: (duration || 0).toString(),
  });
  return [
    {
      storeId: 1,
      storeName: "string",
      departmentId: 1,
      departmentName: "string",
      score: 0.5,
      riskFactor: 3,
      latitude: 15,
      longitude: 25,
      risk: "Capacity",
    },
  ] as ResourceRisk[];
}
