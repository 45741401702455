import React, { useEffect, useRef, useState } from "react";
import { StyledButton } from "../styles";
import { Button, Popover } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useHeader } from "../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../layouts/components/header/models";
import CustomMenuItem from "../MenuItem";
import { useLocationModal } from "./enlarged/context";
import { LocationModalContextInterface } from "./enlarged/model";
import { useAuth } from "../../../auth/context";
export default function StoreFilter() {
  const { user } = useAuth();

  const [anchorStoreEl, setAnchorStoreEl] = useState<HTMLElement | null>();
  const StoreButtonRef = useRef<HTMLDivElement>(null);
  const openStoreMenu = Boolean(anchorStoreEl);
  const handleStoreMenuClose = () => {
    setAnchorStoreEl(undefined);
  };

  const handleStoreMenuClick = () => {
    if (stores?.length && stores?.length > 5) {
      handleOpen();
    } else {
      if (StoreButtonRef) {
        setAnchorStoreEl(StoreButtonRef.current);
      }
    }
  };
  const {
    stores,
    locationId,
    handleLocationChange,
    region,
    handleLocationGroupChange,
  } = useHeader() as HeaderContextInterface;
  const { handleOpen, onLocationSelected } =
    useLocationModal() as LocationModalContextInterface;

  useEffect(() => {
    if (stores && stores.length > 0) {
      if (
        user?.role === "Store Manager" ||
        user?.role === "Department Manager"
      ) {
        onLocationSelected(stores[0].name, stores[0].regionId, "area");
        onLocationSelected(stores[0].name, stores[0].storeId, "store");

        handleLocationGroupChange(stores[0].regionId);
        handleLocationChange(user!.storeId);
      }
    }
  }, [user, stores]);

  return (
    <>
      <StyledButton
        aria-controls={openStoreMenu ? "Store-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openStoreMenu ? "true" : undefined}
        aria-describedby="Store-menu"
        ref={StoreButtonRef}
      >
        <Button
          fullWidth
          sx={{
            justifyContent: "start",
            alignItems: "center",
            color: "design.darkText",
          }}
          onClick={handleStoreMenuClick}
        >
          <LocationOnOutlinedIcon sx={{ mr: 2 }} />
          {stores?.find((x) => x.storeId === locationId)?.name ||
            `All Stores In ${region?.name || "UK"}`}
          <ExpandMoreIcon sx={{ ml: "auto", color: "design.darkText" }} />
        </Button>
      </StyledButton>
      <Popover
        id="store-menu"
        anchorEl={anchorStoreEl}
        open={openStoreMenu}
        onClose={handleStoreMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: StoreButtonRef.current?.clientWidth,
            padding: "10px",
          },
        }}
        sx={{
          mt: 2,
        }}
      >
        {!user?.storeId && (
          <CustomMenuItem
            onClick={() => {
              handleLocationChange(undefined);
              handleStoreMenuClose();
            }}
            isselected={!locationId}
          >
            All Addresses
          </CustomMenuItem>
        )}
        {stores?.map((store) => (
          <CustomMenuItem
            onClick={() => {
              handleLocationChange(store.storeId);
              handleStoreMenuClose();
            }}
            isselected={store.storeId === locationId}
            key={store.storeId}
          >
            {store.name}
          </CustomMenuItem>
        ))}
      </Popover>
    </>
  );
}
