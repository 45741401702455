import Axios from "axios";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // headers: {
  //   Accept: "application/json",
  //   "Content-Type": "application/json",
  // },
  withCredentials: true,
});
