import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { LayoutContextInterface, useLayout } from "../../context";

export default function Header() {
  const { setPopupSidebar, backButton } = useLayout() as LayoutContextInterface;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ bgcolor: "white" }}>
        <Toolbar>
          {backButton ?? (
            <Box
              sx={{
                width: "96px",
                bgcolor: "design.400",
                height: "28px",
                borderRadius: 0.5,
              }}
            ></Box>
          )}
          <IconButton
            aria-label="Shrink Menu"
            sx={{ ml: "auto", color: "design.800" }}
            onClick={() => {
              setPopupSidebar(true);
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
