import { Button, Popover } from "@mui/material";
import { StyledButton } from "../styles";
import { useRef, useState } from "react";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CustomMenuItem from "../MenuItem";
import { useHeader } from "../../../layouts/components/header/context/context";

export enum DurationType {
  Week = 0,
  Period = 3,
  Quarter = 2,
}

export default function DurationFilter() {
  const { duration, handleDurationChange } = useHeader()!;
  const [anchorDurationEl, setAnchorDurationEl] =
    useState<HTMLElement | null>();
  const durationButtonRef = useRef<HTMLDivElement>(null);
  const openDurationMenu = Boolean(anchorDurationEl);
  const handleDurationMenuClose = () => {
    setAnchorDurationEl(undefined);
  };
  const handleDurationMenuClick = () => {
    if (durationButtonRef) {
      setAnchorDurationEl(durationButtonRef.current);
    }
  };

  return (
    <>
      <StyledButton
        aria-controls={openDurationMenu ? "duration-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openDurationMenu ? "true" : undefined}
        aria-describedby="duration-menu"
        ref={durationButtonRef}
      >
        <Button
          fullWidth
          sx={{
            justifyContent: "start",
            alignItems: "center",
            color: "design.darkText",
          }}
          onClick={handleDurationMenuClick}
        >
          <AccessTimeIcon sx={{ mr: 2 }} /> By {DurationType[duration]}
          <ExpandMoreIcon sx={{ ml: "auto", color: "design.darkText" }} />
        </Button>
      </StyledButton>
      <Popover
        id="duration-menu"
        anchorEl={anchorDurationEl}
        open={openDurationMenu}
        onClose={handleDurationMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: durationButtonRef.current?.clientWidth,
            padding: "10px",
          },
        }}
        sx={{
          mt: 2,
        }}
      >
        <CustomMenuItem
          onClick={() => {
            handleDurationChange(DurationType.Quarter);
            handleDurationMenuClose();
          }}
          isselected={duration === DurationType.Quarter}
        >
          By Quarter
        </CustomMenuItem>

        <CustomMenuItem
          onClick={() => {
            handleDurationChange(DurationType.Period);
            handleDurationMenuClose();
          }}
          isselected={duration === DurationType.Period}
        >
          By Period
        </CustomMenuItem>

        <CustomMenuItem
          onClick={() => {
            handleDurationChange(DurationType.Week);
            handleDurationMenuClose();
          }}
          isselected={duration === DurationType.Week}
        >
          By Week
        </CustomMenuItem>
      </Popover>
    </>
  );
}
