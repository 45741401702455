// ** MUI Imports
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createGenericContext } from "../../../../utils";
import { HeaderContextInterface } from "../models";
// ** Type Import
import { Settings } from "../../../../core/context/settingsContext";

// ** Hook Import
import { useSettings } from "../../../../core/hooks/useSettings";
import { useQuery } from "@tanstack/react-query";
import {
  getDepartments,
  getRegion,
  getRegions,
  getStores,
} from "../../../../api/estate";
import { DurationType } from "../../../../features/filter/TimeFilter/duration";
import { useAuth } from "../../../../auth/context";
import { ApplicationDate, getDates } from "../../../../api/dates";
import moment from "moment";

const [useHeader, HeaderContextProvider] =
  createGenericContext<HeaderContextInterface | undefined>();

const HeaderProvider = (props: any) => {
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const weekNumber = searchParams.get("weekNumber") || "";
  const yearNumber = parseInt(searchParams.get("year") || "");
  const [currentDate, setCurrentDate] = useState<ApplicationDate>();
  const quarter = searchParams.get("quarter") || "";

  const [duration, setDuration] = useState(DurationType.Week);

  const { data: dates } = useQuery(
    ["dates-get", duration, yearNumber],
    async () => getDates(yearNumber.toString(), duration)
  );

  useEffect(() => {
    if (!weekNumber) {
      let current = dates?.filter((s) => s.isCurrent);

      if (current && current.length > 0) {
        handleDateChange(current[0]);
      }
    }
  }, [weekNumber, dates]);

  const locationId = parseInt(searchParams.get("locationId") || "");
  const departmentId = !!searchParams.get("departmentId")
    ? parseInt(searchParams.get("departmentId") || "")
    : undefined;
  const locationGroupId = !!searchParams.get("locationGroupId")
    ? parseInt(searchParams.get("locationGroupId") || "")
    : undefined;
  const { settings, saveSettings } = useSettings();

  const handleDateChange = (date: ApplicationDate): void => {
    setCurrentDate(date);
    setSearchParams((searchParams) => {
      searchParams.set("duration", duration.toString());
      searchParams.set("weekNumber", date.weekNumber.toString());
      searchParams.set("year", date.year.toString());

      return searchParams;
    });
  };

  useEffect(() => {
    if (!yearNumber && user) {
      setSearchParams({
        year: moment(
          user.companyPeriodStartDates[user.companyPeriodStartDates.length - 1]
        )
          .year()
          .toString(),
        duration: duration.toString(),
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      searchParams &&
      searchParams.get("weekNumber") &&
      searchParams.get("year") &&
      !currentDate &&
      dates
    ) {
      // find the date with weeknumber and year.
      let match = dates.filter(
        (s) =>
          s.weekNumber.toString() == searchParams.get("weekNumber") &&
          s.year.toString() == searchParams.get("year")
      );

      if (match.length > 0) setCurrentDate(match.pop());
    }
  }, [searchParams, dates]);

  const { data: stores } = useQuery(["stores"], getStores);

  const { data: departments } = useQuery(
    ["departments", locationId],
    () => getDepartments(locationId),
    {
      enabled: !!locationId,
    }
  );

  const { data: regions } = useQuery(["regions"], getRegions);

  const { data: region } = useQuery(
    ["regions", locationGroupId],
    async () => {
      if (!!locationGroupId) {
        return await getRegion(locationGroupId);
      }
    },
    {
      enabled: !!locationGroupId,
    }
  );

  useEffect(() => {
    if (user?.theme?.color) {
      handleChangeThemeColor(
        "themeColor",
        "user",
        user?.theme.color?.primary,
        user?.theme.color?.rga,
        user?.theme.color?.charts
      );
    }
  }, [user?.theme]);

  const handleChangeThemeColor = (
    field: keyof Settings,
    value: Settings[keyof Settings],
    colorCombination: any,
    rga: any,
    charts: any
  ): void => {
    saveSettings({
      ...settings,
      [field]: value,
      colorCombination,
      rga: rga,
      charts: charts,
      userFontFamily: "Mulish",
    });
  };

  const handleDepartmentChange = (id?: number) => {
    if (!!id) {
      setSearchParams((searchParams) => {
        searchParams.set("departmentId", id.toString());
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => {
        searchParams.delete("departmentId");
        return searchParams;
      });
    }
  };

  const handleLocationChange = (id?: number) => {
    if (!!id) {
      setSearchParams((searchParams) => {
        searchParams.set("locationId", id.toString());
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => {
        searchParams.delete("locationId");
        return searchParams;
      });
    }
  };

  const handleLocationGroupChange = (id?: number) => {
    if (!!id) {
      setSearchParams((searchParams) => {
        searchParams.set("locationGroupId", id.toString());
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => {
        searchParams.delete("locationGroupId");
        return searchParams;
      });
    }
  };

  const handleDurationChange = (type: DurationType) => {
    setDuration(type);

    setSearchParams((searchParams) => {
      searchParams.set("duration", type.toString());
      return searchParams;
    });
  };

  const contextValue = {
    stores,
    departments,
    weekNumber,
    locationId,
    locationGroupId,
    departmentId,
    handleDepartmentChange,
    handleLocationChange,
    handleDateChange,
    quarter,
    duration,
    yearNumber,
    regions,
    handleLocationGroupChange,
    region,
    currentDate,
    dates,
    handleDurationChange,
  };

  return (
    <HeaderContextProvider value={contextValue}>
      {props.children}
    </HeaderContextProvider>
  );
};

export { HeaderProvider, useHeader };
