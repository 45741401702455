import React, { useMemo } from "react";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getResourceRisk } from "../../../../api/metrics";
import { GridColDef } from "@mui/x-data-grid";
import { ResourceRisk } from "../../../../models";
import { getPercent } from "../../../../utils";
import Value from "../../../../utils/value-display";
import TableDataGrid from "../../../../features/table";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Loading } from "../../../drivers/components/driver-card/loading";
import { LoadingButton } from "@mui/lab";
import { AreaManagerMap } from "../area-manager-map";
import { thresholds } from "../../../../core/utils/color-threshold";

interface Props {
  mapView?: boolean;
}
export default function ResourceRiskWidget({ mapView }: Props) {
  const { currentDate, locationGroupId, duration, locationId } =
    useHeader() as HeaderContextInterface;
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const theme: any = useTheme();
  const { fetchNextPage, data, isLoading, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [
        "resource-risk",
        locationGroupId,
        undefined,
        duration,
        currentDate,
      ],
      queryFn: ({ pageParam = 1 }) =>
        getResourceRisk(
          currentDate!.weekNumber,
          currentDate!.year.toString(),
          duration,
          locationGroupId,
          locationId,
          pageParam
        ),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    });

  const columns = useMemo<GridColDef<ResourceRisk>[]>(() => {
    return [
      {
        field: !locationId ? "storeName" : "departmentName",
        headerName: !locationId ? "Store name" : "Department Name",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Typography
              sx={{
                fontSize: "16px",
                color: "design.darkText",
              }}
            >
              {!locationId ? params.row.storeName : params.row.departmentName}
            </Typography>
          );
        },
      },
      {
        field: "score",
        headerName: "Score",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Box sx={{ display: "inline" }}>
              <Value
                percentage={getPercent(params.row.score * 100, 100)}
                showPercentage
                iconOnly
              />
            </Box>
          );
        },
      },
      {
        field: "riskFactor",
        headerName: "Risk Factors",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "inline-flex",
                gap: 2,
                alignItems: "center",
                color: thresholds(getPercent(params.row.riskFactor * 100, 100)),
              }}
            >
              <Value
                percentage={getPercent(params.row.riskFactor * 100, 100)}
                iconOnly
                noValue
              />
              {params.row.risk}
            </Box>
          );
        },
      },
      {
        field: "action",
        headerName: "",
        renderCell: (params) => {
          return (
            <IconButton
              sx={{
                border: "1px solid #E3ECFD",
              }}
              size="small"
              onClick={() => {}}
            >
              <ChevronRightIcon
                sx={{
                  color: "#00104D",
                }}
              />
            </IconButton>
          );
        },
      },
    ];
  }, [locationId]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 5,
        }}
      >
        <Loading />
      </Box>
    );
  }
  return (
    <>
      <AreaManagerMap
        data={(
          (data?.pages.flatMap((rows) => rows) || []) as ResourceRisk[]
        ).map((x) => ({
          name: x.storeName,
          latitude: x.latitude,
          longitude: x.longitude,
          onClick: () => {},
          color: thresholds(getPercent(x.score * 100, 100), 1, theme),
          content: (
            <>
              <Card>
                <CardHeader
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "design.darkText",
                          fontWeight: 800,
                        }}
                      >
                        {!locationId ? x.storeName : x.departmentName}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Score
                      <Box>{x.score}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Risk Factors
                      <Box>{x.riskFactor}</Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ),
        }))}
      />
      <TableDataGrid
        getRowId={(row: ResourceRisk) =>
          !locationId ? row.storeId : row.departmentId
        }
        data={(data?.pages.flatMap((rows) => rows) || []) as ResourceRisk[]}
        columns={columns}
        headerField={!locationId ? "storeName" : "departmentName"}
        actionField="action"
      />
      {mapView && hasNextPage && (
        <LoadingButton
          variant="outlined"
          onClick={() => {
            fetchNextPage();
          }}
          sx={{
            mt: 2,
            color: theme.palette.design["800"],
            borderColor: theme.palette.design["800"],
          }}
          loading={isFetchingNextPage}
          fullWidth={isMobile}
        >
          Load More
        </LoadingButton>
      )}
    </>
  );
}
