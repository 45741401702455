import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocationModal } from "./context";
import { LocationModalContextInterface } from "./model";
import { useQuery } from "@tanstack/react-query";
import {
  getRegion,
  getStores,
  getStoresByRegion,
} from "../../../../api/estate";
import Recent from "./recent";
import Search from "./search";
export default function Store() {
  const [allSelected, setAllSelected] = useState(false);
  const { onLocationSelected, onSelectAllStores, isSearching, storeGroupId } =
    useLocationModal() as LocationModalContextInterface;

  const { data: stores } = useQuery(
    ["stores", storeGroupId],
    async () => {
      if (!!storeGroupId) {
        return await getStoresByRegion(storeGroupId);
      } else {
        return await getStores();
      }
    },
    {
      enabled: !!storeGroupId,
    }
  );

  const { data: region } = useQuery(
    ["regions", storeGroupId],
    async () => {
      if (!!storeGroupId) {
        return await getRegion(storeGroupId);
      }
    },
    {
      enabled: !!storeGroupId,
    }
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color="primary.contrastText"
          sx={{
            fontWeight: 800,
          }}
        >
          {region?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Search />
      </Grid>
      {!isSearching && (
        <>
          <Grid item xs={12}>
            <Recent />
          </Grid>
          <Grid container item xs={12} spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <Radio
                  checked={allSelected}
                  onClick={() => {
                    setAllSelected(allSelected ? false : true);
                  }}
                  value={true}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                All stores
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary.contrastText"
                sx={{
                  fontWeight: 800,
                }}
              >
                Select store
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ width: "100%" }}>
                {stores?.map((store, i) => (
                  <React.Fragment key={store.storeId}>
                    <ListItem
                      secondaryAction={
                        <IconButton
                          sx={{
                            border: 1,
                          }}
                          size="small"
                          onClick={() => {
                            onLocationSelected(
                              store.name,
                              store.storeId,
                              "store"
                            );
                          }}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={store.name || "<No Name>"} />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ color: "white", bgcolor: "design.800" }}
                disabled={!allSelected}
                onClick={() => onSelectAllStores()}
              >
                Confirm selection
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
