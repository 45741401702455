export function getPercent(x: number, y: number) {
  if( !x || !y){
    return 0
  }
  const percentage: any = (x / y) * 100;

  if (y == 0) return 100;

  return parseInt(parseFloat(percentage).toFixed(0));
}
