import { Avatar, Box, Chip, IconButton } from "@mui/material";
import { thresholdStatus } from "../core/utils/color-threshold";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";

const Value = ({
  percentage,
  showPercentage,
  iconOnly,
  onTrack,
  noValue,
}: any) => {
  const threshold = thresholdStatus(percentage, onTrack);
  if (threshold === "Ahead") {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 1,
          }}
        >
          {!noValue && (
            <>
              {percentage}
              {showPercentage ? "/100" : "%"}
            </>
          )}
          {iconOnly ? (
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#FCCFDF",
                color: "#FE1950",
                ml: "auto",
              }}
            >
              <RemoveIcon />
            </Avatar>
          ) : (
            <Chip
              icon={
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "#FCCFDF",
                    color: "#FE1950 !important",
                  }}
                >
                  <RemoveIcon />
                </Avatar>
              }
              label="Ahead"
              variant="outlined"
              sx={{
                ml: "auto",
                color: "#FE1950",
                borderColor: "#FEECF2 !important",
                p: 0.5,
                bgcolor: "#FEECF2",
              }}
            />
          )}
        </Box>
      </>
    );
  } else if (threshold === "On Track") {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 1,
          }}
        >
          {!noValue && (
            <>
              {percentage}
              {showPercentage ? "/100" : "%"}
            </>
          )}
          {iconOnly ? (
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#C7EFDE",
                color: "#03825B",
                ml: "auto",
              }}
            >
              <CheckIcon fontSize="small" />
            </Avatar>
          ) : (
            <Chip
              icon={
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "#C7EFDE",
                    color: "#03825B !important",
                  }}
                >
                  <CheckIcon fontSize="small" />
                </Avatar>
              }
              label="On Track   "
              variant="outlined"
              sx={{
                ml: "auto",
                color: "#03825B",
                borderColor: "#E7F8F1 !important",
                p: 0.5,
                bgcolor: "#E7F8F1",
              }}
            />
          )}
        </Box>
      </>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: 1,
      }}
    >
      {!noValue && (
        <>
          {percentage}
          {showPercentage ? "/100" : "%"}
        </>
      )}
      {iconOnly ? (
        <Avatar
          variant="rounded"
          sx={{
            width: 24,
            height: 24,
            bgcolor: "#FE1950",
            color: "white",
            ml: "auto",
          }}
        >
          !
        </Avatar>
      ) : (
        <Chip
          icon={
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#FE1950",
                color: "white !important",
              }}
            >
              !
            </Avatar>
          }
          label="Behind"
          variant="outlined"
          //   size="small"
          sx={{
            ml: "auto",
            color: "#FE1950",
            borderColor: "#FEECF2 !important",
            p: 0.5,
            bgcolor: "#FEECF2",
          }}
        />
      )}
    </Box>
  );
};

export function ThresholdDisplay({
  percentage,
  showPercentage,
  iconOnly,
  noValue,
  threshold,
}: any) {
  if (threshold === "Ahead") {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 1,
          }}
        >
          {!noValue && (
            <>
              {percentage}
              {showPercentage ? "/100" : "%"}
            </>
          )}
          {iconOnly ? (
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#FCCFDF",
                color: "#FE1950",
                ml: "auto",
              }}
            >
              <RemoveIcon />
            </Avatar>
          ) : (
            <Chip
              icon={
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "#FCCFDF",
                    color: "#FE1950 !important",
                  }}
                >
                  <RemoveIcon />
                </Avatar>
              }
              label="Ahead"
              variant="outlined"
              sx={{
                ml: "auto",
                color: "#FE1950",
                borderColor: "#FEECF2 !important",
                p: 0.5,
                bgcolor: "#FEECF2",
              }}
            />
          )}
        </Box>
      </>
    );
  } else if (threshold === "On Track") {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 1,
          }}
        >
          {!noValue && (
            <>
              {percentage}
              {showPercentage ? "/100" : "%"}
            </>
          )}
          {iconOnly ? (
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#C7EFDE",
                color: "#03825B",
                ml: "auto",
              }}
            >
              <CheckIcon fontSize="small" />
            </Avatar>
          ) : (
            <Chip
              icon={
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "#C7EFDE",
                    color: "#03825B !important",
                  }}
                >
                  <CheckIcon fontSize="small" />
                </Avatar>
              }
              label="On Track   "
              variant="outlined"
              sx={{
                ml: "auto",
                color: "#03825B",
                borderColor: "#E7F8F1 !important",
                p: 0.5,
                bgcolor: "#E7F8F1",
              }}
            />
          )}
        </Box>
      </>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: 1,
      }}
    >
      {!noValue && (
        <>
          {percentage}
          {showPercentage ? "/100" : "%"}
        </>
      )}
      {iconOnly ? (
        <Avatar
          variant="rounded"
          sx={{
            width: 24,
            height: 24,
            bgcolor: "#FE1950",
            color: "white",
            ml: "auto",
          }}
        >
          !
        </Avatar>
      ) : (
        <Chip
          icon={
            <Avatar
              variant="rounded"
              sx={{
                width: 24,
                height: 24,
                bgcolor: "#FE1950",
                color: "white !important",
              }}
            >
              !
            </Avatar>
          }
          label="Behind"
          variant="outlined"
          //   size="small"
          sx={{
            ml: "auto",
            color: "#FE1950",
            borderColor: "#FEECF2 !important",
            p: 0.5,
            bgcolor: "#FEECF2",
          }}
        />
      )}
    </Box>
  );
}
export default Value;
