import React from "react";
import TableDataGrid from "../../../../features/table";
import { GridColDef } from "@mui/x-data-grid";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { useQuery } from "@tanstack/react-query";
import { IconButton, styled, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getAreaCapacityReport } from "../../../../api/matches";
import SuccessIcon from "../custom-icons/success-icons";
import BehindIcon from "../custom-icons/behind-icon";
import SecondaryButton from "../../../../features/button/secondary";
import { useNavigate } from "react-router-dom";
import getPageLink from "../../../../core/components/utils/getPageLink";

export default function AreaCapacity({ main }: { main: boolean }) {
  const { locationId, departmentId, duration, currentDate } = useHeader()!;
  const navigate = useNavigate();
  const link = getPageLink("/area-capacity");
  const { data } = useQuery(
    [
      "area-capacity",
      locationId,
      departmentId,
      duration,
      currentDate?.weekNumber,
      currentDate?.year,
    ],
    async () =>
      getAreaCapacityReport(
        duration,
        currentDate!.weekNumber,
        currentDate!.year.toString(),
        locationId,
        departmentId
      ),
    {
      enabled: true,
    }
  );

  const CellContent = styled("div")({
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  });

  const columns: GridColDef[] = [
    {
      field: "storeName",
      headerName: "Store name",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "16px",
              color: "design.darkText",
            }}
          >
            {params.row.storeName}
          </Typography>
        );
      },
    },
    {
      field: "flexCapacity",
      headerName: "Flex Capacity",
      // flex: 1,
      width: 180,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <CellContent>
            <Typography
              sx={{
                fontSize: "16px",
                color: "design.darkText",
              }}
            >
              {params.row.flexCapacity}/100
            </Typography>
            <span>
              {params.row.flexCapacityOnTrack ? (
                <SuccessIcon />
              ) : (
                <BehindIcon />
              )}
            </span>
          </CellContent>
        );
      },
    },
    {
      field: "recruitment",
      headerName: "Recruitment",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <>
            {params.row.recruitmentOnTrack ? (
              <SuccessIcon text={"On track"} />
            ) : (
              <BehindIcon text={"Under recruited"} />
            )}
          </>
        );
      },
    },
    {
      field: "availability",
      headerName: "Availability",
      flex: 1,
      minWidth: 150,
      headerClassName: "cell-header",
      renderCell: (params) => {
        return (
          <CellContent>
            <Typography
              sx={{
                fontSize: "16px",
                color: "design.darkText",
              }}
            >
              {params.row.availability}/100
            </Typography>
            <span>
              {params.row.availabilityOnTrack ? (
                <SuccessIcon />
              ) : (
                <BehindIcon />
              )}
            </span>
          </CellContent>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      renderCell: (params) => {
        return (
          <IconButton
            sx={{
              border: "1px solid #E3ECFD",
            }}
            size="small"
            onClick={() => {}}
          >
            <ChevronRightIcon
              sx={{
                color: "#00104D",
              }}
            />
          </IconButton>
        );
      },
    },
  ];

  if (!data) return <></>;

  return (
    <>
      <TableDataGrid
        data={data}
        columns={columns}
        getRowId={(row: any) => row.storeName}
        headerField="storeName"
        defaultVisible={10}
        actionField="action"
        hidePaginationButton={!main}
      />
      {!main && (
        <SecondaryButton
          disableRipple
          onClick={() => {
            navigate(link);
          }}
        >
          See all
        </SecondaryButton>
      )}
    </>
  );
}
