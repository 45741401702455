import React, { useMemo } from "react";
import { useHeader } from "../../../../layouts/components/header/context/context";
import { HeaderContextInterface } from "../../../../layouts/components/header/models";
import { useQuery } from "@tanstack/react-query";
import { GridColDef } from "@mui/x-data-grid";
import { ManagementEffectiveness } from "../../../../models";
import { getPercent } from "../../../../utils";
import Value from "../../../../utils/value-display";
import TableDataGrid from "../../../../features/table";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Loading } from "../../../drivers/components/driver-card/loading";
import { replaceQueryString } from "../../../../core/components/utils/removeQueryString";
import { LoadingButton } from "@mui/lab";
import { AreaManagerMap } from "../area-manager-map";
import { thresholds } from "../../../../core/utils/color-threshold";
import { getAreaCapacityReport } from "../../../../api/matches";

interface Props {
  mapView?: boolean;
}
export default function StoreTable({ mapView }: Props) {
  const { locationId, duration, currentDate } =
    useHeader() as HeaderContextInterface;

  const replaceQuery = replaceQueryString();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const theme: any = useTheme();
  const { data, isLoading } = useQuery(
    ["management-effectiveness", currentDate, duration, locationId],
    async () =>
      getAreaCapacityReport(
        duration,
        currentDate!.weekNumber,
        currentDate!.year.toString(),
        locationId
      ),
    {
      enabled: true,
    }
  );

  const columns = useMemo<GridColDef<ManagementEffectiveness>[]>(() => {
    return [
      {
        field: "storeName",
        headerName: "Store name",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
      },
      {
        field: "total",
        headerName: "Score",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={getPercent(params.row.total * 100, 100)}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "skillsAlignment",
        headerName: "Skills Alignment",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={params.row.skillsAlignment}
              onTrack={params.row.skillsAlignmentOnTrack}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "flexCapacity",
        headerName: "Flex Capacity",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={params.row.flexCapacity}
              onTrack={params.row.flexCapacityOnTrack}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "trainingCompletion",
        headerName: "Training Completion",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={params.row.trainingCompletion}
              onTrack={params.row.trainingCompletionOnTrack}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "holidayPhasing",
        headerName: "Holiday Phasing",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={params.row.holidayPhasing}
              onTrack={params.row.holidayPhasingOnTrack}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "availability",
        headerName: "Availability",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={params.row.availability}
              onTrack={params.row.availabilityOnTrack}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "difficultyOfChange",
        headerName: "Difficulty of change",
        flex: 1,
        minWidth: 150,
        headerClassName: "cell-header",
        renderCell: (params) => {
          return (
            <Value
              percentage={params.row.difficultyOfChange}
              onTrack={params.row.difficultyOfChangeOnTrack}
              showPercentage
              iconOnly
            />
          );
        },
      },
      {
        field: "action",
        headerName: "",
        renderCell: (params) => {
          return (
            <IconButton
              sx={{
                border: "1px solid #E3ECFD",
              }}
              size="small"
              onClick={() => {
                replaceQuery(
                  "locationId",
                  params.row.storeId,
                  "/management-effectiveness"
                );
              }}
            >
              <ChevronRightIcon
                sx={{
                  color: "#00104D",
                }}
              />
            </IconButton>
          );
        },
      },
    ];
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 5,
        }}
      >
        <Loading />
      </Box>
    );
  }
  return (
    <>
      <AreaManagerMap
        data={((data || []) as ManagementEffectiveness[]).map((x) => ({
          name: x.storeName,
          latitude: x.latitude,
          longitude: x.longitude,
          onClick: () =>
            replaceQuery("locationId", x.storeId, "/management-effectiveness"),
          color: thresholds(getPercent(x.total * 100, 100), 1, theme),
          content: (
            <>
              <Card>
                <CardHeader
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "design.darkText",
                          fontWeight: 800,
                        }}
                      >
                        {x.storeName}
                      </Typography>

                      <Typography
                        component="span"
                        sx={{
                          color: "design.light",
                        }}
                      >
                        {x.total}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Skills alignment
                      <Box>{x.skillsAlignment}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Flex capacity
                      <Box>{x.flexCapacity}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Training completion
                      <Box>{x.trainingCompletion}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Holiday phasing
                      <Box>{x.holidayPhasing}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Availability
                      <Box>{x.availability}</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "design.darkText",
                      }}
                    >
                      Difficulty of change
                      <Box>{x.difficultyOfChange}</Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ),
        }))}
      />
      <TableDataGrid
        getRowId={(row: ManagementEffectiveness) => row.storeId}
        data={(data || []) as ManagementEffectiveness[]}
        columns={columns}
        defaultVisible={10}
        headerField="storeName"
        actionField="action"
        hidePaginationButton={!mapView}
      />
    </>
  );
}
