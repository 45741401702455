import { useTheme } from "@mui/material/styles";
import { hexToRGBA } from "./hex-to-rgba";
import { ExtendedThemeOptions } from "../layouts/types";
export const thresholds = (
  percentage: number,
  opacity = 1,
  theme?: ExtendedThemeOptions | undefined | any
) => {
  if (!theme) theme = useTheme() as any;
  let color = theme?.palette?.design["behind"];
  if (percentage >= 85) {
    color = theme.palette?.design["ahead"];
  } else if (percentage >= 70) {
    color = theme.palette?.design["on track"];
  }
  return color && hexToRGBA(color, opacity);
};

export const thresholdStatus = (percentage: number, onTrack: boolean) => {
  if (percentage > 100) {
    return "Ahead";
  } else if (onTrack) {
    return "On Track";
  } else {
    return "Behind";
  }
};
