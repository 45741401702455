import { Box, Typography } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
interface Props {
  text?: string;
}
export default function SuccessIcon({ text }: Props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          color: "#03825B",
          bgcolor: "#C7EFDE",
          p: 1,
          borderRadius: 1,
        }}
      >
        <CheckIcon sx={{ verticalAlign: "middle" }} />
      </Box>
      {text && <Typography sx={{ ml: 1, color: "#03825B" }}>{text}</Typography>}
    </Box>
  );
}
