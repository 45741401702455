import React from "react";
import { useAuth } from "../../auth/context";

export const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated, isLoading, user } = useAuth();

  if (isLoading) {
    return <>Loading</>;
  } else if (!isAuthenticated) {
    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/login`;
  } else {
    return <>{children}</>;
  }

  return <></>;
};
