import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import navigation from "../../../navigation/vertical";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import getPageLink from "../../../core/components/utils/getPageLink";
import navigations from "./navigation-list";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { LayoutContextInterface, useLayout } from "../../context";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useAuth } from "../../../auth/context";

const getClassName = (text: string) => {
  switch (text) {
    case "Dashboard":
      return "first-step";
    case "Workload Inputs":
      return "workload-input-step-1";
    case "Matches":
      return "match-step-1";
    default:
      return "";
  }
};

interface DesktopSidebarProps {
  isSmall?: boolean;
  isMobile?: boolean;
}

//Todo: Check if default location should still be populated from mockoon
export default function DesktopSidebar({
  isSmall,
  isMobile,
}: DesktopSidebarProps) {
  const location = useLocation();
  const { user, logout } = useAuth();

  const { setPopupSidebar } = useLayout() as LayoutContextInterface;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: !isSmall ? (isMobile ? "100vw" : "216px") : "64px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        background: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isSmall && (
          <IconButton
            aria-label="Shrink Menu"
            sx={{ color: "design.800", padding: 0, margin: "auto" }}
            onClick={() => {
              setPopupSidebar(true);
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isMobile && (
          <>
            <Box sx={{ ml: "auto" }}>
              <IconButton
                aria-label="Shrink Menu"
                onClick={() => {
                  setPopupSidebar(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </>
        )}
        {!isSmall && !isMobile && (
          <>
            <Box
              sx={{
                width: "96px",
                bgcolor: "design.400",
                height: "28px",
                borderRadius: 0.5,
              }}
            ></Box>
            <IconButton
              aria-label="Shrink Menu"
              sx={{ border: 1, padding: 0 }}
              onClick={() => {
                setPopupSidebar(false);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </>
        )}
      </Box>
      <Box sx={{ mt: 5 }}>
        {navigations.map((navigation, i) => (
          <List
            sx={{
              mt: 5,
            }}
            key={i}
            subheader={
              isSmall ? (
                i !== 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 8,
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "2px",
                        bgcolor: "design.400",
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                )
              ) : (
                <ListSubheader
                  component="div"
                  sx={{
                    p: 0,
                    background: "transparent",
                    fontWeight: 400,
                    textTransform: "none",
                  }}
                >
                  {navigation.subheader}
                </ListSubheader>
              )
            }
          >
            {navigation.items.map((nav: any) => (
              <NavLink
                to={`${getPageLink(nav.path)}`}
                key={nav.path}
                style={{
                  textDecoration: "none",
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                <ListItemButton
                  sx={{
                    bgcolor: !!matchPath(nav.path ?? "", location.pathname)
                      ? "design.200"
                      : "",
                    borderRadius: 1,
                    px: 0,
                    pl: isSmall ? 0 : 2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "design.800",
                      margin: isSmall ? "auto" : "",
                    }}
                  >
                    {nav.icon}
                  </ListItemIcon>
                  {!isSmall && (
                    <ListItemText
                      primary={
                        <Typography sx={{ color: "design.800" }}>
                          {nav.title}
                        </Typography>
                      }
                      className={getClassName(nav.title)}
                    ></ListItemText>
                  )}
                  {!isSmall && (
                    <ChevronRightIcon
                      sx={{
                        color: "design.800",
                        ml: "auto",
                      }}
                    />
                  )}
                </ListItemButton>
              </NavLink>
            ))}
          </List>
        ))}
      </Box>
      <Box sx={{ mt: "auto" }}>
        <ListItemButton sx={{ p: 0, mb: 2 }}>
          <ListItemIcon sx={{ color: "design.800" }}>
            <Avatar
              sx={{ bgcolor: "design.200", color: "design.800" }}
              variant="rounded"
            >
              {user?.name[0]}
            </Avatar>
          </ListItemIcon>
          {!isSmall && (
            <ListItemText
              primary={
                <Typography sx={{ color: "design.800" }}>
                  {user?.name}
                </Typography>
              }
            ></ListItemText>
          )}

          {isMobile && <ChevronRightIcon sx={{ ml: "auto" }} />}
        </ListItemButton>
        <ListItemButton sx={{ p: 0 }} onClick={() => logout()}>
          <ListItemIcon sx={{ color: "design.800" }}>
            <Avatar
              sx={{ bgcolor: "transparent", color: "design.800" }}
              variant="rounded"
            >
              <LogoutIcon />
            </Avatar>
          </ListItemIcon>
          {!isSmall && (
            <ListItemText
              primary={
                <Typography sx={{ color: "design.800" }}>Sign out</Typography>
              }
            ></ListItemText>
          )}
        </ListItemButton>
      </Box>
    </Box>
  );
}
