import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";

interface CustomFilterProps {
  children: ReactNode;
  open: boolean;
  handleClose: Function;
  handleBack?: Function;
}
export default function CustomFilter({
  children,
  open,
  handleClose,
  handleBack,
}: CustomFilterProps) {
  return (
    <>
      <Drawer anchor="left" open={open} onClose={() => handleClose()}>
        <div style={{ height: "100%", padding: "16px", width: "100vw" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={() => (handleBack || handleClose)()}>
              <ChevronLeftIcon
                sx={{
                  color: "primary.dark",
                  borderColor: "primary.lighter",
                  borderRadius: "100%",
                  borderWidth: 1,
                  borderStyle: "solid",
                  mr: 1,
                }}
              />
              Back
            </Button>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            {children}
          </Container>
        </div>
      </Drawer>
    </>
  );
}
1;
