import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  styled,
  Button,
  useMediaQuery,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { TableDataGrid$Props } from "./utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { el } from "@fullcalendar/core/internal-common";

function CustomSortIconAsc() {
  return (
    <ExpandLessIcon
      sx={{
        color: "design.darkText",
      }}
      fontSize="small"
    />
  );
}

function CustomSortIconDesc() {
  return (
    <ExpandMoreIcon
      sx={{
        color: "design.darkText",
      }}
      fontSize="small"
    />
  );
}

export const TableDataGrid = (props: TableDataGrid$Props) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );

  if (!isSmallScreen || props.alwaysLargeView) {
    return <DesktopView {...props} />;
  } else {
    return <MobileView {...props} />;
  }
};
const DesktopView = ({
  data,
  columns,
  sortModel,
  defaultVisible,
  getRowId,
  onSortChange,
  hidePaginationButton,
}: TableDataGrid$Props) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const theme: any = useTheme();

  useEffect(() => {
    setPaginationModel({ page: 0, pageSize: defaultVisible || 100 });
  }, [defaultVisible]);

  return (
    <>
      <div>
        <DataGrid
          getRowId={getRowId}
          rows={data}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: sortModel,
            },
            pagination: {
              paginationModel: paginationModel,
            },
          }}
          slots={{
            columnSortedAscendingIcon: CustomSortIconAsc,
            columnSortedDescendingIcon: CustomSortIconDesc,
          }}
          hideFooterPagination
          paginationMode="client"
          pagination={true}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          autoHeight
          //   rowSelection={false}
          disableColumnSelector
          disableColumnFilter
          rowHeight={56}
          disableColumnMenu
          onSortModelChange={(e) => {
            if (onSortChange) {
              onSortChange(e[0]);
            }
          }}
          style={{ border: "none" }}
          //   showColumnVerticalBorder={false}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: "none",
            },
            "& .MuiDataGrid-withBorderColor": {
              border: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: "visible",
            },
            "& .MuiDataGrid-row": {
              height: "56px",
              background: "none",
              "&:hover": {
                background: "none",
              },
            },
            "& .MuiDataGrid-cell": {
              background: "#fff",
              color: "black",
              minHeight: "50px !important",
              maxHeight: "50px !important",
              "&:first-of-type": {
                borderRadius: "16px 0 0 16px",
                borderRight: "2px solid #f1f4f9",
                position: "relative",
              },
              "&:hover": {
                background: "#fff",
              },
              "&:last-of-type": {
                borderRadius: "0 16px 16px 0",
              },
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              display: "block",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "space-between",
              ".MuiDataGrid-columnHeaderTitle": {
                color: "#00104D",
                fontSize: "14px",
              },
            },
            "& .MuiIconButton-root": {
              color: theme.palette.design.darkText,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textTransform: "none",
              fontWeight: 800,
            },
            "& .cell-header": {
              fontWeight: 500,
              "& .MuiDataGrid-menuIcon": {
                display: "none",
              },
              "&:focus": {
                outline: "none",
              },
            },
          }}
        />
      </div>
      {!hidePaginationButton && (
        <>
          {defaultVisible && data.length > paginationModel.pageSize && (
            <SecondaryButton
              disableRipple
              onClick={() => {
                setPaginationModel({
                  page: paginationModel.page,
                  pageSize: paginationModel.pageSize + 10,
                });
              }}
            >
              Show more ({data.length - paginationModel.pageSize})
            </SecondaryButton>
          )}
        </>
      )}
    </>
  );
};
const MobileView = ({
  data,
  columns,
  defaultVisible,
  getRowId,
  headerField,
  actionField,
  hidePaginationButton,
}: TableDataGrid$Props) => {
  const getValue = (row: any, column?: any) => {
    let value: any = "";
    if (!column) {
      return;
    }
    if (column?.renderCell) {
      return (
        <column.renderCell
          value={row[column.field]}
          field={column.field}
          row={row}
          id={getRowId(row)}
        />
      );
    }
    if (!value) {
      value = row[column?.field];
    }
    return value;
  };
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    setPaginationModel({ page: 0, pageSize: defaultVisible || 100 });
  }, [defaultVisible]);

  const actionColumn = useMemo(
    () => columns.find((x) => x.field === actionField),
    [columns, actionField]
  );
  const headerColumn = useMemo(
    () => columns.find((x) => x.field === headerField),
    [columns, headerField]
  );

  if (data.length === 0) {
    <Grid container my={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            No rows
          </CardContent>
        </Card>
      </Grid>
    </Grid>;
  }
  return (
    <>
      <Grid container spacing={2} gap={2}>
        {data.slice(0, paginationModel.pageSize).map((row, i) => {
          return (
            <Grid item xs={12} key={i}>
              <Card>
                {row[headerField as string] && (
                  <CardHeader
                    title={
                      <Typography
                        variant="h5"
                        color="primary.contrastText"
                        sx={{
                          fontWeight: 800,
                        }}
                      >
                        {headerColumn && getValue(row, headerColumn)}
                      </Typography>
                    }
                    action={actionColumn && getValue(row, actionColumn)}
                    sx={{
                      pb: 2,
                      borderBottom: "1px solid #E3ECFD",
                      mb: 4,
                    }}
                  />
                )}
                <CardContent>
                  <Grid container gap={4}>
                    {columns
                      .filter(
                        (x) =>
                          x.field !== headerField && x.field !== actionField
                      )
                      .map((column) => (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "design.darkText",
                          }}
                        >
                          {column.headerName}
                          <Box>{getValue(row, column)}</Box>
                        </Grid>
                      ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {!hidePaginationButton && (
        <>
          {defaultVisible && data.length > paginationModel.pageSize && (
            <SecondaryButton
              disableRipple
              onClick={() => {
                setPaginationModel({
                  page: paginationModel.page,
                  pageSize: paginationModel.pageSize + 10,
                });
              }}
            >
              Show more ({data.length - paginationModel.pageSize})
            </SecondaryButton>
          )}
        </>
      )}
    </>
  );
};

const SecondaryButton = styled(Button)({
  border: "solid 1px #003DA5",
  color: "#003DA5",
  marginTop: "20px",
  textTransform: "none",
  background: "none",
  fontSize: "16px",
  fontWeight: 400,
  width: "200px",
  ":hover": {
    background: "none",
  },
});

export default TableDataGrid;
